import { createRouter, createWebHistory } from "vue-router";

import OverviewPage from "../pages/OverviewPage.vue";
import AdminPage from "../pages/AdminPage.vue";
import LoginPage from "../pages/LoginPage.vue";
import PolymerPage from "../pages/PolymerPage.vue";
import ServicesPage from "../pages/ServicesPage.vue";
const routes = [
  {
    path: "/",
    name: "overviewPage",
    component: OverviewPage,
  },
  {
    path: "/services",
    name: "services",
    component: ServicesPage,
    meta: {
      title: "بازار و خدمات اکس پلیمر",
      description: "اطلاعت کامل خدمات و محصولات ارائه شده توسط اکس پلیمر.",
    },
  },
  {
    path: "/polymer/:id",
    name: "polymer",
    component: PolymerPage,
  },
  {
    path: "/login",
    name: "loginPage",
    component: LoginPage,
  },
  {
    path: "/admin",
    name: "adminPage",
    component: AdminPage,
    meta: { requiresAuth: true },
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

// history: createWebHashHistory(),
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }
    return { top: 0 };
  },
});

export default router;
// ,{
//   path: "/admin",
//   component: Sidebar,
//   redirect: "/admin/overview",
// children: [
//   {
//     path: "baseInfo",
//     name: "BaseInfo",
//     component: BaseInfoPage,
//     meta: { requiresAuth: true },
//   }
// ],
// },
// { path: "/:pathMatch(.*)*", redirect: "/admin/overview" },s
