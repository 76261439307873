<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <transition name="modal-fade">
    <div v-if="show" class="modal-container-full">
      <div class="modal-overlay" @click="closeModal"></div>
      <button id="close-swal" class="button" @click="closeModal">
        <font-awesome-icon :icon="['fa', 'fa-close']" />
      </button>
      <div class="modal-content-full headerclose">
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { ref, onMounted, onUnmounted } from "vue";

// eslint-disable-next-line no-unused-vars

export default {
  setup() {
    const show = ref(false);
    const openModal = () => {
      show.value = true;
      document.body.style.overflow = "hidden";
    };
    const closeModal = () => {
      show.value = false;
      document.body.style.overflow = "";
    };
    const closeModalOnEscape = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    onMounted(() => {
      document.addEventListener("keydown", closeModalOnEscape);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", closeModalOnEscape);
    });
    return { show, openModal, closeModal };
  },
  props: {
    size: String,
  },
};
</script>
<style scoped lang="scss">
[data-theme="dark"] {
  .close-button {
    svg {
      fill: black;
    }
  }
}

.close-button {
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 20000;

  svg {
    width: 15px;
  }
}

.headerclose {
  position: relative;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-container-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  .modal-body {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 80px 0;
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); /* Adjust blur effect */
}

button#close-swal {
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3334;
  color: white;
  border: 2px solid #fff;
  border-radius: 50px;
  cursor: pointer;
  position: absolute !important;
  top: 0;
  transform: translateY(10px);
  z-index: 1001;
}

.modal-content {
  background-color: white;
  width: 80%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-sm {
  background-color: white;
  width: 30%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-md {
  background-color: white;
  width: 45%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-lg {
  background-color: white;
  width: 60%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-full {
  --sb-track-color: #f5f5f7;
  --sb-thumb-color: #2068e1;
  --sb-size: 16px;
  background-color: white;

  width: 95%;
  height: 93%;
  /* padding: 10px; */
  border-radius: 10px;
  z-index: 1000;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
  box-sizing: border-box;
  /* scrollbar-width: none; */
  /* &::-webkit-scrollbar {
    display: none;
  } */
}

.modal-content-full::-webkit-scrollbar {
  width: var(--sb-size);
}

.modal-content-full::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 10px; /* گرد کردن گوشه‌های track */
  margin: 10px 5px; /* ایجاد فاصله از بالا و پایین */
}

.modal-content-full::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 10px; /* گرد کردن گوشه‌های thumb */
  border: 4px solid transparent; /* ایجاد فاصله از لبه‌ها */
  background-clip: padding-box; /* تضمین می‌کند که thumb داخل padding قرار گیرد */
}

@supports not selector(::-webkit-scrollbar) {
  .modal-content-full {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 1024px) {
  .modal-content-md {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .modal-content {
    width: 80%;
  }
  .modal-content-sm {
    width: 70%;
    font-size: 0.9rem;
  }
  .modal-content-md {
    width: 85%;
  }
  .modal-content-lg {
    width: 85%;
  }
  .modal-content-full {
    background-color: white;
    width: 95%;
    height: 85%;
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px;
    /* .modal-body {
      display: flex;
      height: 100%;
      justify-content: center;
      text-align: center;
      margin: 20px 0;
      table {
        display: flex;
      }
    } */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
