<template>
  <div class="news-container">
    <div class="section">
      <div class="news-out">
        <div class="image">
          <NewsSvg />
        </div>
        <h2 class="header">
          اخبار و مقالات
          <br /><span class="bold">اِکس پلیمر</span>
        </h2>
        <p class="details">
          برای مشاهده محتوای مطالب آن را انتخاب کنید و برای دریافت آخرین اخبار و
          مقالات ایمیل خود را ثبت کنید.
        </p>
        <div class="input">
          <input v-model="email" placeholder="آدرس ایمیل" />
          <button @click="submitForm">ثبت</button>
          <p v-if="message" :class="toastType">{{ message }}</p>
        </div>
      </div>
      <div
        class="spinner-border text-primary"
        role="status"
        v-if="loading"
      ></div>
      <div class="news-wrapper" v-else>
        <div class="news" id="news">
          <div class="new" v-for="(post, index) in posts" v-bind:key="index">
            <img :src="post.img" loading="lazy" />
            <div class="text">{{ post.title }}</div>
            <a class="link" :href="post.link">مطالعه کنید </a>
          </div>
        </div>
        <a href="https://expolymer.ir/blog" class="buttons">
          <button>مشاهده همه</button>
        </a>
      </div>
    </div>
  </div>
  <div class="news-container mobile" id="news">
    <h2 class="header">
      اخبار و مقالات
      <span class="bold">اِکس پلیمر</span>
    </h2>
    <div class="sub">برای مشاهده محتوای مطالب، آن را انتخاب کنید.</div>
    <div class="wrapper">
      <div
        class="spinner-border text-primary"
        role="status"
        v-if="loading"
      ></div>
      <div
        class="news"
        v-else
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
      >
        <div class="new">
          <img :src="posts[currentIndex]?.img" loading="lazy" />
          <div class="text">{{ posts[currentIndex].title }}</div>
          <a class="link" :href="posts[currentIndex].link">مطالعه کنید </a>
        </div>
      </div>
      <div class="dots">
        <span
          :class="currentIndex === 0 && 'active'"
          @click="setCurrentIndex(0)"
        ></span>
        <span
          :class="currentIndex === 1 && 'active'"
          @click="setCurrentIndex(1)"
        ></span>
        <span
          :class="currentIndex === 2 && 'active'"
          @click="setCurrentIndex(2)"
        ></span>
        <span
          :class="currentIndex === 3 && 'active'"
          @click="setCurrentIndex(3)"
        ></span>
      </div>
      <a href="https://expolymer.ir/blog" class="buttons">
        <button class="button-main">مشاهده همه</button>
      </a>
    </div>
  </div>
</template>
<script setup>
import { ref, onBeforeMount } from "vue";
import NewsSvg from "./svgs/NewsSvg .vue";
import axios from "axios";
import { useMutation, provideApolloClient } from "@vue/apollo-composable"; // وارد کردن provideApolloClient
import gql from "graphql-tag";
import { createApolloClient } from "./apolloClient"; // فرض کنید که Apollo Client شما اینجا ساخته شده است

const posts = ref([]);
const loading = ref(false);
const currentIndex = ref(0);

// ایجاد Apollo Client
const apolloClient = createApolloClient();

// فراهم کردن Apollo Client
provideApolloClient(apolloClient);

// تعریف Mutation برای ثبت ایمیل
const ADD_SUBSCRIBER = gql`
  mutation AddSubscriber($email: String!, $firstName: String!, $listId: Int!) {
    addMailPoetSubscriber(
      input: { email: $email, firstName: $firstName, listId: $listId }
    ) {
      success
      message
    }
  }
`;

const email = ref("");
const message = ref("");
const toastType = ref("success"); // نوع پیام (خطا یا موفقیت)
const { mutate, onDone, onError } = useMutation(ADD_SUBSCRIBER);
const isSubmitting = ref(false); // Flag to disable button while submitting
const submitForm = () => {
  if (!email.value) {
    message.value = "لطفاً یک ایمیل وارد کنید.";
    toastType.value = "error"; // نوع پیام خطا
    return;
  }

  mutate({
    email: email.value,
    firstName: "کاربر", // مقدار پیش‌فرض (می‌توان مقدار واقعی را دریافت کرد)
    listId: 1, // ID لیست موردنظر در MailPoet
  });
  isSubmitting.value = true; // Disable the button while submitting
  onDone(({ data }) => {
    if (data.addMailPoetSubscriber.success) {
      message.value = "ایمیل شما با موفقیت ثبت شد!";
      toastType.value = "success"; // موفقیت
    } else {
      // بررسی پیام خطا از سمت سرور
      if (
        data.addMailPoetSubscriber.message === "This subscriber already exists."
      ) {
        message.value = "این ایمیل قبلاً ثبت شده است.";
      } else {
        message.value = data.addMailPoetSubscriber.message;
      }
      toastType.value = "error"; // خطا
    }
    // بعد از 5 ثانیه پیام را مخفی کنیم
    setTimeout(() => {
      message.value = "";
      toastType.value = "";
    }, 5000); // 5000 میلی‌ثانیه معادل 5 ثانیه است
  });

  onError((error) => {
    message.value = "خطایی رخ داد. لطفاً دوباره امتحان کنید.";
    toastType.value = "error"; // خطا
    console.error(error);
  });
};

// دریافت داده‌های بلاگ از GraphQL API وردپرس
const getPosts = async () => {
  try {
    loading.value = true;
    const query = `
      {
        posts(first: 4) {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    `;

    const { data } = await axios.post(
      `${process.env.VUE_APP_BASE_WORDPRESS}/blog/graphql`,
      { query }
    );

    posts.value = data.data.posts.nodes.map((post) => ({
      title: post.title,
      img: post.featuredImage?.node?.sourceUrl || "/assets/default-image.jpg",
      link: `${process.env.VUE_APP_BASE_WORDPRESS}/blog${post.uri}`,
    }));

    loading.value = false;
  } catch (err) {
    console.error("خطا در دریافت داده‌ها:", err);
    loading.value = false;
  }
};

// مقداردهی اولیه داده‌ها هنگام بارگذاری کامپوننت
onBeforeMount(() => {
  getPosts();
});

// تغییر ایندکس اسلاید
const setCurrentIndex = (index) => {
  currentIndex.value = index;
};

// مدیریت حرکت لمسی (Swipe)
let startX = 0; // نقطه شروع لمس
let endX = 0; // نقطه پایان لمس

const onTouchStart = (event) => {
  startX = event.touches[0].clientX; // مقدار X اولین لمس را ذخیره می‌کنیم
};

const onTouchMove = (event) => {
  endX = event.touches[0].clientX; // مقدار X هنگام حرکت لمس را ذخیره می‌کنیم
};

const onTouchEnd = () => {
  const diff = startX - endX; // تفاوت بین شروع و پایان لمس

  if (diff > 50) {
    // **سوایپ به چپ (بعدی)**
    if (currentIndex.value < posts.value.length - 1) {
      currentIndex.value++;
    } else {
      currentIndex.value = 0; // اگر آخرین اسلاید بود، برگردد به اول
    }
  } else if (diff < -50) {
    // **سوایپ به راست (قبلی)**
    if (currentIndex.value > 0) {
      currentIndex.value--;
    } else {
      currentIndex.value = posts.value.length - 1; // اگر اولین اسلاید بود، برود به آخرین
    }
  }
};
</script>

<style lang="scss" scoped>
.news-container {
  position: relative;
  width: 100%;
  padding-top: 8rem;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
  z-index: 0;
  flex-direction: column;
  .section {
    align-items: center;
  }
  &.mobile {
    display: none;
  }
  .news-out {
    color: white;
    top: 70px;
    width: 25%;
    background-color: #2068e1;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px !important;
    -webkit-box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
    box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
    background: linear-gradient(#2068e1b3, #2068e1b3),
      /* لایه نیمه‌شفاف بالا */ url("../assets/hex.webp"),
      /* تصویر وسط */ #2068e1; /* رنگ اصلی زیرین */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    background-blend-mode: normal; /* ترکیب پس‌زمینه */
    .image {
      animation: 2s infinite alternate ease-in-out upAndDown;
      svg {
        fill: white;
        width: 80px;
        height: 120px;
      }
    }
    .header {
      font-weight: bold;
      .bold {
        color: #0f2851;
      }
    }
    .details {
      text-align: justify;
      hyphens: auto;
      letter-spacing: 0.5px;
    }
    .input {
      height: 51px;
      padding: 6px;
      background-color: white;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        height: 100%;
        border: none;
        outline: none;
        padding-right: 10px;
        width: 100%;
      }
      button {
        width: 60px;
        height: 100%;
        border-radius: 10px;
        border: none;
        background-color: #0f2851;
        color: white;
        &:hover {
          transform: scale(0.9);
          background-color: #2068e1;
          transition: 0.3s;
        }
      }
      p {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        z-index: 9999;
      }
      p.success {
        background-color: #4caf50;
      }

      p.error {
        background-color: #f44336;
      }
    }
  }
  .section {
    width: 100%;
    height: 300px;
    display: flex;
    background-color: #0f2851;
    color: white;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 20px 0 0 20px;
    padding: 10px 0px 10px 10px;
    .news-wrapper {
      display: flex;
      flex-direction: column;
      width: 73%;
      max-width: 72vw;
      height: 100%;
      justify-content: space-between;
      .news {
        display: flex;
        align-self: flex-end;
        /* margin-right: auto; */
        overflow: hidden;
        /* padding-right: 140px; */
        gap: 15px;
        width: 100%;
        padding-bottom: 20px;
        /* flex: 3; */

        .new {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
          .text {
            font-size: 14px;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            height: 40%;
          }
          a {
            text-decoration: none;
            font-size: 14px;
            cursor: pointer;
          }
          img {
            border-radius: 8px;
            height: 100%;
            width: 100%;
          }
        }
      }
      .buttons {
        border-top: 1px solid white;
        direction: ltr;
        button {
          width: 150px;
          height: 36px;
          margin-top: 20px;
          border-radius: 10px;
          border: none;
          background-color: #2068e1;
          color: white;
          &:hover {
            transition: 0.2s;
            background-color: white;
            color: #0f2851;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .news-container {
    position: relative;
    width: 100%;
    padding: 100px 10px;
    display: flex;
    justify-content: center;
    z-index: 0;
    flex-direction: column;
    .section {
      align-items: center;
    }
    &.mobile {
      display: none;
    }
    .news-out {
      color: white;
      width: 35%;
      background-color: #2068e1;
      height: 340px;
      border-radius: 20px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      -webkit-box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
      -moz-box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
      box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
      background-image: url("../assets/hex.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      .image {
        animation: 2s infinite alternate ease-in-out upAndDown;
        svg {
          fill: white;
          width: 80px;
          height: 120px;
        }
      }
      .header {
        font-weight: bold;
        .bold {
          color: #0f2851;
        }
      }
      .details {
        text-align: justify;
        hyphens: auto;
        letter-spacing: 0.5px;
      }
      .input {
        height: 51px;
        padding: 6px;
        background-color: white;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          height: 100%;
          border: none;
          outline: none;
          padding-right: 10px;
          width: 100%;
        }
        button {
          width: 60px;
          height: 100%;
          border-radius: 10px;
          border: none;
          background-color: #0f2851;
          color: white;
          &:hover {
            transform: scale(0.9);
            background-color: #2068e1;
            transition: 0.3s;
          }
          p {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            padding: 15px;
            border-radius: 5px;
            color: white;
            font-weight: bold;
            z-index: 9999;
          }
          p.success {
            background-color: #4caf50;
          }

          p.error {
            background-color: #f44336;
          }
        }
      }
    }
    .section {
      width: 100%;
      height: 250px;
      display: flex;
      background-color: #0f2851;
      color: white;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 20px 0 0 20px;
      padding: 10px 0px 10px 10px;
      .news-wrapper {
        display: flex;
        flex-direction: column;
        width: 63%;
        max-width: 60vw;
        height: 100%;
        justify-content: space-between;
        .news {
          display: flex;
          align-self: flex-end;
          /* margin-right: auto; */
          overflow: hidden;
          /* padding-right: 140px; */
          gap: 15px;
          width: 100%;
          padding-bottom: 20px;
          /* flex: 3; */

          .new {
            width: 260px;
            display: flex;
            flex-direction: column;
            .text {
              font-size: 14px;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            a {
              margin-top: 5px;
              text-decoration: none;
              font-size: 14px;
              cursor: pointer;
            }
            img {
              border-radius: 8px;
              height: auto;
              margin-bottom: 10px;
              width: 100%;
            }
          }
        }
        .buttons {
          border-top: 1px solid white;
          direction: ltr;
          button {
            width: 150px;
            height: 36px;
            margin-top: 20px;
            border-radius: 10px;
            border: none;
            background-color: #2068e1;
            color: white;
            &:hover {
              transition: 0.2s;
              background-color: white;
              color: #0f2851;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .news-container {
    width: 100%;
    display: none;
    &.mobile {
      display: inline;
      .header {
        text-align: center;
        /* font-weight: bold; */
        margin-bottom: 10px;
        .bold {
          color: #2068e1;
        }
      }
      .sub {
        color: #b4b4b4;
        text-align: center;
        margin-bottom: 32px;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .dots {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.1rem;
          span {
            width: 7px;
            height: 7px;
            background-color: gray;
            border-radius: 100%;
            margin: 0 5px;
            /* transition: 0.3s; */
            &.active {
              background-color: #0f2851;
              width: 20px;
              border-radius: 8px;
              transition: 0.3s;
            }
          }
        }
        .buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2rem;
          text-decoration: none;
          button {
            width: 130px;
            height: 35px;
            border-radius: 10px;
            border: none;
            font-size: 12px;
            background-color: #0f2851;
            color: white;
            cursor: pointer;
          }
        }
        .news {
          margin: 0 1rem;
          .new {
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            overflow: hidden;
            height: 320px;
            -webkit-box-shadow: -1px 4px 28px -10px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: -1px 4px 28px -10px rgba(0, 0, 0, 0.75);
            box-shadow: -1px 4px 28px -10px rgba(0, 0, 0, 0.75);
            img {
              height: 200px;
              border-radius: 0 !important;
            }
            .text {
              padding: 15px;
              font-size: 14px;
            }
            a {
              font-size: 13px;
              font-weight: bold;
              padding: 15px;
              margin-top: auto;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>
