<template>
  <footer id="cantactus">
    <div class="links-container">
      <div class="container">
        <div class="info">
          <div class="top-pattern">
            <img src="/img/footerPattern.png" alt="pattern" loading="lazy" />
          </div>
          <div class="logo">
            <img
              src="/img/footerLogo.webp"
              alt="expolymer logo"
              loading="lazy"
            />
          </div>
          <div class="social-icons-mobile" style="display: none">
            <a
              class="social-icon"
              href="https://chat.whatsapp.com/BAOMODOpryhAWeOEupFkTE"
              target="_blank"
            >
              <svg
                fill="#000000"
                height="800px"
                width="800px"
                id="Icon"
                viewBox="0 0 24 24"
                enable-background="new 0 0 24 24"
                xml:space="preserve"
              >
                <g id="WA_Logo">
                  <g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.5,3.5C18.25,1.25,15.2,0,12,0C5.41,0,0,5.41,0,12c0,2.11,0.65,4.11,1.7,5.92
			L0,24l6.33-1.55C8.08,23.41,10,24,12,24c6.59,0,12-5.41,12-12C24,8.81,22.76,5.76,20.5,3.5z M12,22c-1.78,0-3.48-0.59-5.01-1.49
			l-0.36-0.22l-3.76,0.99l1-3.67l-0.24-0.38C2.64,15.65,2,13.88,2,12C2,6.52,6.52,2,12,2c2.65,0,5.2,1.05,7.08,2.93S22,9.35,22,12
			C22,17.48,17.47,22,12,22z M17.5,14.45c-0.3-0.15-1.77-0.87-2.04-0.97c-0.27-0.1-0.47-0.15-0.67,0.15
			c-0.2,0.3-0.77,0.97-0.95,1.17c-0.17,0.2-0.35,0.22-0.65,0.07c-0.3-0.15-1.26-0.46-2.4-1.48c-0.89-0.79-1.49-1.77-1.66-2.07
			c-0.17-0.3-0.02-0.46,0.13-0.61c0.13-0.13,0.3-0.35,0.45-0.52s0.2-0.3,0.3-0.5c0.1-0.2,0.05-0.37-0.02-0.52
			C9.91,9.02,9.31,7.55,9.06,6.95c-0.24-0.58-0.49-0.5-0.67-0.51C8.22,6.43,8.02,6.43,7.82,6.43S7.3,6.51,7.02,6.8
			C6.75,7.1,5.98,7.83,5.98,9.3c0,1.47,1.07,2.89,1.22,3.09c0.15,0.2,2.11,3.22,5.1,4.51c0.71,0.31,1.27,0.49,1.7,0.63
			c0.72,0.23,1.37,0.2,1.88,0.12c0.57-0.09,1.77-0.72,2.02-1.42c0.25-0.7,0.25-1.3,0.17-1.42C18,14.68,17.8,14.6,17.5,14.45z"
                    />
                  </g>
                </g>
              </svg>
            </a>

            <a
              class="social-icon"
              href="https://t.me/+itl1fX2HmUE3NTQ0"
              target="_blank"
            >
              <svg
                aria-hidden="true"
                class="e-font-icon-svg e-fab-telegram-plane"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"
                ></path>
              </svg>
            </a>
            <a
              class="social-icon"
              href="https://www.instagram.com/expolymer.iran/"
              target="_blank"
            >
              <svg
                aria-hidden="true"
                class="e-font-icon-svg e-fab-instagram"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/expolymer"
              target="_blank"
              class="social-icon"
            >
              <svg
                aria-hidden="true"
                class="e-font-icon-svg e-fab-linkedin-in"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path>
              </svg>
            </a>
            <a
              class="social-icon"
              href="https://www.youtube.com/@expolymer"
              target="_blank"
            >
              <svg
                aria-hidden="true"
                class="e-font-icon-svg e-fab-youtube"
                viewBox="0 0 576 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                ></path>
              </svg>
            </a>
            <a
              class="social-icon"
              href="https://www.aparat.com/expolymer"
              target="_blank"
            >
              <svg viewBox="0 0 68.33 68.33">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="brand">
                    <g id="icon--color-black">
                      <path
                        class="cls-1"
                        d="M29.49,2,23.2.36A10.58,10.58,0,0,0,10.25,7.87L8.68,13.8A32.4,32.4,0,0,1,29.49,2Z"
                      />
                      <path
                        class="cls-1"
                        d="M1.9,39.33.36,45.14A10.58,10.58,0,0,0,7.87,58.08l6,1.6A32.41,32.41,0,0,1,1.9,39.33Z"
                      />
                      <path
                        class="cls-1"
                        d="M60.46,10.25,53.73,8.46a32.4,32.4,0,0,1,12.4,21.7l1.85-7A10.58,10.58,0,0,0,60.46,10.25Z"
                      />
                      <path
                        class="cls-1"
                        d="M38.69,66.26,45.14,68a10.58,10.58,0,0,0,12.94-7.51l1.82-6.84A32.42,32.42,0,0,1,38.69,66.26Z"
                      />
                      <path
                        class="cls-2"
                        d="M34.17,4.54A29.63,29.63,0,1,0,63.79,34.17,29.63,29.63,0,0,0,34.17,4.54ZM17.39,19.32a8.46,8.46,0,1,1,6.71,9.91A8.46,8.46,0,0,1,17.39,19.32ZM29.7,44.92A8.46,8.46,0,1,1,23,35,8.46,8.46,0,0,1,29.7,44.92Zm3.59-6.85a3.76,3.76,0,1,1,4.41-3A3.76,3.76,0,0,1,33.29,38.07ZM50.94,49a8.46,8.46,0,1,1-6.71-9.91A8.46,8.46,0,0,1,50.94,49Zm-5.6-15.68a8.46,8.46,0,1,1,9.91-6.71A8.46,8.46,0,0,1,45.34,33.33Z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div class="header">
            <div class="line"></div>
            <p class="text">ارتباط با ما</p>
            <div class="line"></div>
          </div>
          <div class="items-wrapper">
            <a class="item" href="tel:09121002186">
              <div class="image"><FooterFaxSvg /></div>
              <p class="text">موبایل : ۰۹۱۲۱۰۰۲۱۸۶</p>
            </a>
            <a class="item" href="tel:09128403746">
              <div class="image"><FooterFaxSvg /></div>
              <p class="text">موبایل : ۰۹۱۲۸۴۰۳۷۴۶</p>
            </a>
            <a class="item" href="tel:02182804095">
              <div class="image"><FooterPhoneSvg /></div>
              <p class="text">تلفن : ۰۲۱۸۲۸۰۴۰۹۵</p>
            </a>
            <div class="item">
              <div class="image"><FooterCenterOfficeSvg /></div>
              <p class="text">دفتر مرکزی : میدان هروی، حسین آباد</p>
            </div>
            <div class="item">
              <div class="image"><FooterFactorySvg /></div>
              <p class="text">
                کارخانه : شورآباد، مهدی آباد، میدان قائم خیابان امام حسین پلاک
                ۲۱
              </p>
            </div>
          </div>
          <div class="items-wrapper-tablet" style="display: none">
            <div class="items-wrapper-tablet-top">
              <a class="item" href="tel:09121002186">
                <div class="image"><FooterFaxSvg /></div>
                <p class="text">موبایل : ۰۹۱۲۱۰۰۲۱۸۶</p>
              </a>
              <a class="item" href="tel:09128403746">
                <div class="image"><FooterFaxSvg /></div>
                <p class="text">موبایل : ۰۹۱۲۸۴۰۳۷۴۶</p>
              </a>
              <a class="item" href="tel:02182804095">
                <div class="image"><FooterPhoneSvg /></div>
                <p class="text">تلفن : ۰۲۱۸۲۸۰۴۰۹۵</p>
              </a>
            </div>
            <div class="items-wrapper-tablet-bottom">
              <div class="item">
                <div class="image"><FooterCenterOfficeSvg /></div>
                <p class="text">دفتر مرکزی : میدان هروی، حسین آباد</p>
              </div>
              <div class="item">
                <div class="image"><FooterFactorySvg /></div>
                <p class="text">
                  کارخانه : شورآباد، مهدی آباد، میدان قائم خیابان امام حسین پلاک
                  ۲۱
                </p>
              </div>
            </div>
          </div>
          <div class="mail-mobile" style="display: none">
            <div class="input">
              <input v-model="email" placeholder="آدرس ایمیل" />
              <button @click="submitForm" :disabled="isSubmitting">
                اشتراک خبرنامه
              </button>
              <p v-if="message" :class="toastType">{{ message }}</p>
            </div>
          </div>
        </div>
        <div class="links">
          <div class="mail">
            <h3 class="header">
              <span class="bold">اشتراک </span>
              <span style="color: #ffffff">خبرنامه </span>
            </h3>
            <div class="input">
              <input v-model="email" placeholder="آدرس ایمیل" />
              <button @click="submitForm" :disabled="isSubmitting">
                ارسال
              </button>
              <p v-if="message" :class="toastType">{{ message }}</p>
            </div>
          </div>
          <div class="line"></div>
          <div class="cattegory">
            <div class="radif">
              <h3 class="header">بازار و خدمات</h3>
              <div class="custom-line"></div>
              <div class="radif-childs">
                <div class="radif-child">
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/warehousing/"
                    target="_blank"
                    >انبارداری</a
                  >
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/washing-materials/"
                    target="_blank"
                    >شست و شوی مواد</a
                  >
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/feather/"
                    target="_blank"
                    >پرک و آسیاب</a
                  >
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/granulate/"
                    target="_blank"
                    >کامپاند و گرانول</a
                  >
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/export/"
                    target="_blank"
                    >واردات و صادرات</a
                  >
                </div>
                <div class="radif-child">
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/materials-consultation/"
                    target="_blank"
                    >مشاوره خرید مواد</a
                  >
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/production/"
                    target="_blank"
                    >محصولات</a
                  >
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/polymer-additives/"
                    target="_blank"
                    >افزودنی ها و مستربچ</a
                  >
                  <a
                    class="item"
                    href="https://expolymer.ir/blog/industrial-devices/"
                    target="_blank"
                    >دستگاه‌های صنعتی</a
                  >
                </div>
              </div>
            </div>
            <div class="radif">
              <h3 class="header">دسترسی سریع</h3>
              <div class="line"></div>
              <a
                class="item"
                href="https://expolymer.ir/blog/news-articles/"
                target="_blank"
                >اخبار و مقالات</a
              >
              <a
                class="item"
                href="https://expolymer.ir/blog/about-us/"
                target="_blank"
                >درباره ما</a
              >
            </div>
          </div>
          <div class="line"></div>
          <div class="socials">
            <h4 class="header">
              <span class="bold">ما را </span>
              <span style="color: #ffffff">دنبال کنید </span>
            </h4>
            <div class="svgs">
              <div class="hand"><FooterHandSvg /></div>
            </div>
            <div class="social-icons">
              <a
                class="social-icon"
                href="https://chat.whatsapp.com/BAOMODOpryhAWeOEupFkTE"
                target="_blank"
              >
                <svg
                  fill="#000000"
                  height="800px"
                  width="800px"
                  id="Icon"
                  viewBox="0 0 24 24"
                  enable-background="new 0 0 24 24"
                  xml:space="preserve"
                >
                  <g id="WA_Logo">
                    <g>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.5,3.5C18.25,1.25,15.2,0,12,0C5.41,0,0,5.41,0,12c0,2.11,0.65,4.11,1.7,5.92
			L0,24l6.33-1.55C8.08,23.41,10,24,12,24c6.59,0,12-5.41,12-12C24,8.81,22.76,5.76,20.5,3.5z M12,22c-1.78,0-3.48-0.59-5.01-1.49
			l-0.36-0.22l-3.76,0.99l1-3.67l-0.24-0.38C2.64,15.65,2,13.88,2,12C2,6.52,6.52,2,12,2c2.65,0,5.2,1.05,7.08,2.93S22,9.35,22,12
			C22,17.48,17.47,22,12,22z M17.5,14.45c-0.3-0.15-1.77-0.87-2.04-0.97c-0.27-0.1-0.47-0.15-0.67,0.15
			c-0.2,0.3-0.77,0.97-0.95,1.17c-0.17,0.2-0.35,0.22-0.65,0.07c-0.3-0.15-1.26-0.46-2.4-1.48c-0.89-0.79-1.49-1.77-1.66-2.07
			c-0.17-0.3-0.02-0.46,0.13-0.61c0.13-0.13,0.3-0.35,0.45-0.52s0.2-0.3,0.3-0.5c0.1-0.2,0.05-0.37-0.02-0.52
			C9.91,9.02,9.31,7.55,9.06,6.95c-0.24-0.58-0.49-0.5-0.67-0.51C8.22,6.43,8.02,6.43,7.82,6.43S7.3,6.51,7.02,6.8
			C6.75,7.1,5.98,7.83,5.98,9.3c0,1.47,1.07,2.89,1.22,3.09c0.15,0.2,2.11,3.22,5.1,4.51c0.71,0.31,1.27,0.49,1.7,0.63
			c0.72,0.23,1.37,0.2,1.88,0.12c0.57-0.09,1.77-0.72,2.02-1.42c0.25-0.7,0.25-1.3,0.17-1.42C18,14.68,17.8,14.6,17.5,14.45z"
                      />
                    </g>
                  </g>
                </svg>
              </a>

              <a
                class="social-icon"
                href="https://t.me/+itl1fX2HmUE3NTQ0"
                target="_blank"
              >
                <svg
                  aria-hidden="true"
                  class="e-font-icon-svg e-fab-telegram-plane"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"
                  ></path>
                </svg>
              </a>
              <a
                class="social-icon"
                href="https://www.instagram.com/expolymer.iran/"
                target="_blank"
              >
                <svg
                  aria-hidden="true"
                  class="e-font-icon-svg e-fab-instagram"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/expolymer"
                target="_blank"
                class="social-icon"
              >
                <svg
                  aria-hidden="true"
                  class="e-font-icon-svg e-fab-linkedin-in"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  ></path>
                </svg>
              </a>
              <a
                class="social-icon"
                href="https://www.youtube.com/@expolymer"
                target="_blank"
              >
                <svg
                  aria-hidden="true"
                  class="e-font-icon-svg e-fab-youtube"
                  viewBox="0 0 576 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                  ></path>
                </svg>
              </a>
              <a
                class="social-icon"
                href="https://www.aparat.com/expolymer"
                target="_blank"
              >
                <svg viewBox="0 0 68.33 68.33">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="brand">
                      <g id="icon--color-black">
                        <path
                          class="cls-1"
                          d="M29.49,2,23.2.36A10.58,10.58,0,0,0,10.25,7.87L8.68,13.8A32.4,32.4,0,0,1,29.49,2Z"
                        />
                        <path
                          class="cls-1"
                          d="M1.9,39.33.36,45.14A10.58,10.58,0,0,0,7.87,58.08l6,1.6A32.41,32.41,0,0,1,1.9,39.33Z"
                        />
                        <path
                          class="cls-1"
                          d="M60.46,10.25,53.73,8.46a32.4,32.4,0,0,1,12.4,21.7l1.85-7A10.58,10.58,0,0,0,60.46,10.25Z"
                        />
                        <path
                          class="cls-1"
                          d="M38.69,66.26,45.14,68a10.58,10.58,0,0,0,12.94-7.51l1.82-6.84A32.42,32.42,0,0,1,38.69,66.26Z"
                        />
                        <path
                          class="cls-2"
                          d="M34.17,4.54A29.63,29.63,0,1,0,63.79,34.17,29.63,29.63,0,0,0,34.17,4.54ZM17.39,19.32a8.46,8.46,0,1,1,6.71,9.91A8.46,8.46,0,0,1,17.39,19.32ZM29.7,44.92A8.46,8.46,0,1,1,23,35,8.46,8.46,0,0,1,29.7,44.92Zm3.59-6.85a3.76,3.76,0,1,1,4.41-3A3.76,3.76,0,0,1,33.29,38.07ZM50.94,49a8.46,8.46,0,1,1-6.71-9.91A8.46,8.46,0,0,1,50.94,49Zm-5.6-15.68a8.46,8.46,0,1,1,9.91-6.71A8.46,8.46,0,0,1,45.34,33.33Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <p>
          © کلیه حقوق مادی و معنوی این وب‌سایت متعلق به شرکت اِکس پلیمر است.
        </p>
        <div>
          <span>طراحی و اجرا :</span>
          <a
            href="https://tetras.ir/%d8%b7%d8%b1%d8%a7%d8%ad%db%8c-%d9%88%d8%a8-%d8%b3%d8%a7%db%8c%d8%aa/"
          >
            <span class="logo"><TetrasSvg /></span>
            <span>تیم طراحی و تبلیغاتی تتراس</span>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
import TetrasSvg from "./svgs/TetrasSvg.vue";
import FooterHandSvg from "./svgs/FooterHandSvg.vue";
import FooterPhoneSvg from "./svgs/FooterPhoneSvg.vue";
import FooterFaxSvg from "./svgs/FooterFaxSvg.vue";
import FooterFactorySvg from "./svgs/FooterFactorySvg.vue";
import FooterCenterOfficeSvg from "./svgs/FooterCenterOfficeSvg.vue";
import { ref } from "vue";
import { useMutation, provideApolloClient } from "@vue/apollo-composable"; // وارد کردن provideApolloClient
import gql from "graphql-tag";
import { createApolloClient } from "./apolloClient"; // فرض کنید که Apollo Client شما اینجا ساخته شده است

// ایجاد Apollo Client
const apolloClient = createApolloClient();

// فراهم کردن Apollo Client
provideApolloClient(apolloClient);

// تعریف Mutation برای ثبت ایمیل
const ADD_SUBSCRIBER = gql`
  mutation AddSubscriber($email: String!, $firstName: String!, $listId: Int!) {
    addMailPoetSubscriber(
      input: { email: $email, firstName: $firstName, listId: $listId }
    ) {
      success
      message
    }
  }
`;

const email = ref("");
const message = ref("");
const toastType = ref("success"); // نوع پیام (خطا یا موفقیت)
const { mutate, onDone, onError } = useMutation(ADD_SUBSCRIBER);
const isSubmitting = ref(false); // Flag to disable button while submitting
const submitForm = () => {
  if (!email.value) {
    message.value = "لطفاً یک ایمیل وارد کنید.";
    toastType.value = "error"; // نوع پیام خطا
    return;
  }

  mutate({
    email: email.value,
    firstName: "کاربر", // مقدار پیش‌فرض (می‌توان مقدار واقعی را دریافت کرد)
    listId: 1, // ID لیست موردنظر در MailPoet
  });
  isSubmitting.value = true; // Disable the button while submitting
  onDone(({ data }) => {
    if (data.addMailPoetSubscriber.success) {
      message.value = "ایمیل شما با موفقیت ثبت شد!";
      toastType.value = "success"; // موفقیت
    } else {
      // بررسی پیام خطا از سمت سرور
      if (
        data.addMailPoetSubscriber.message === "This subscriber already exists."
      ) {
        message.value = "این ایمیل قبلاً ثبت شده است.";
      } else {
        message.value = data.addMailPoetSubscriber.message;
      }
      toastType.value = "error"; // خطا
    }
    // بعد از 5 ثانیه پیام را مخفی کنیم
    setTimeout(() => {
      message.value = "";
      toastType.value = "";
    }, 5000); // 5000 میلی‌ثانیه معادل 5 ثانیه است
  });

  onError((error) => {
    message.value = "خطایی رخ داد. لطفاً دوباره امتحان کنید.";
    toastType.value = "error"; // خطا
    console.error(error);
  });
};
</script>

<style lang="scss" scoped>
footer {
  background: linear-gradient(0deg, #0f2851 85%, transparent 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  width: 100vw;
  margin-top: 12rem;
  .links-container {
    display: flex;
    width: 100vw;
    max-width: 1140px;
    align-items: flex-end;
    .container {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0 auto;
      padding: 0 20px;
      flex-direction: row;
      z-index: 1;
      max-width: 1140px !important;
      .info {
        width: 33.33%;
        background-color: #2068e1;
        border-radius: 20px;
        padding: 20px;
        -webkit-box-shadow: 0px 31px 28px -22px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 31px 28px -22px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 31px 28px -22px rgba(0, 0, 0, 0.75);
        /* سه پس‌زمینه: رنگ اصلی، تصویر، رنگ نیمه‌شفاف */
        background: linear-gradient(#2068e1b3, #2068e1b3),
          /* لایه نیمه‌شفاف بالا */ url("../assets/hex.webp"),
          /* تصویر وسط */ #2068e1; /* رنگ اصلی زیرین */
        background-size: inherit;
        background-repeat: no-repeat;
        background-blend-mode: normal; /* ترکیب پس‌زمینه */
        background-position: -50px center;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        height: initial;
        flex-grow: 0;
        align-self: initial;
        flex-wrap: wrap;
        gap: 10px 10px;
        row-gap: 10px;
        column-gap: 10px;
        margin-bottom: -5px;
        .top-pattern {
          margin-top: -30%;
          transform: translateX(-14%); /* متناسب با عرض صفحه */
          img {
            transform: rotateY(180deg);
            width: 100%;
            height: auto;
            max-width: 357px;
            max-height: 148px;
          }
        }
        .logo {
          text-align: center;
          width: 100%;
          img {
            width: 257px;
            height: 59px;
          }
        }
        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 100%;
          color: white !important;
          .line {
            border-top: 2px solid white;
            width: 30%;
          }
        }
        .item {
          display: flex;
          align-items: center;
          font-size: 14px;
          cursor: pointer;
          text-decoration: none;
          color: white !important;
          width: 100%;
          .text {
            direction: rtl;
            unicode-bidi: embed;
          }
          .image {
            margin-left: 5px;
            svg {
              fill: white;
              width: 65px;
              height: 65px;
            }
          }
          &:hover {
            transform: scale(0.9);
            transition: 0.6s;
          }
        }
      }
      .links {
        height: 100%;
        width: 66.66%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 10px;
        margin-top: 90px;
        .line {
          width: 50%;
          border-top: 1px solid white;
        }
        .mail {
          width: 100%;
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          .header {
            font-weight: bold;
            width: 30%;
            .bold {
              color: #2068e1;
            }
          }
          .input {
            height: 50px;
            width: 70%;
            background-color: white;
            display: flex;
            padding: 4px;
            justify-content: space-between;

            border-radius: 100px;
            input {
              height: 100%;
              background-color: transparent;
              border: none;
              outline: none;
              padding-right: 15px;
              width: 70%;
            }
            button {
              width: 120px;
              height: 100%;
              background-color: #2068e1;
              color: white;
              border: none;
              border-radius: 100px;
              &:hover {
                background-color: #0f2851;
                transition: 0.3s;
              }
            }
            p {
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
              padding: 15px;
              border-radius: 5px;
              color: white;
              font-weight: bold;
              z-index: 9999;
            }
            p.success {
              background-color: #4caf50;
            }

            p.error {
              background-color: #f44336;
            }
          }
        }
        .cattegory {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 100px;
          .radif {
            width: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
            .radif-childs {
              display: flex;
              flex-direction: row;
              gap: 20px;
              justify-content: space-between;
              width: 100%;
              .radif-child {
                display: flex;
                flex-direction: column;
              }
            }
          }
          .header {
            color: #2068e1;
            font-weight: bold;
            font-size: 20px;
          }
          .line {
            border-top: #2068e1 solid 2px;
            width: 50%;
            margin: 5px 0;
            margin-bottom: 10px;
          }
          .custom-line {
            width: 100%;
            border-top: #2068e1 solid 2px;
            margin: 5px 0;
            margin-bottom: 10px;
          }
          .item {
            text-decoration: none !important;
            color: white !important;
            padding-bottom: 10px;
            cursor: pointer;
            font-size: 14px;
            &:hover {
              color: #2068e1 !important;
              transition: 0.2s;
            }
          }
        }
        .socials {
          width: 100%;
          display: flex;
          align-items: center;
          .header {
            font-size: 22px;
            font-weight: bold;
            margin: 0;
            .bold {
              color: #2068e1;
            }
          }
          .svgs {
            .hand {
              animation: 2s infinite alternate ease-in-out rightAndLeft;
              margin: 0 25px;
              svg {
                width: 50px;
                height: 50px;
                fill: #2068e1;
              }
            }
          }
          .social-icons {
            /* padding-right: 50px; */
            display: flex;
            /* justify-content: flex-end; */
            margin-right: auto;
            gap: 35px;
            /* justify-content: space-between; */

            /* width: 100%; */
            svg {
              width: 30px;
              height: 30px;
              fill: white;
              &:hover {
                fill: #4a87e2;
                transform: scale(0.9);
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    background-color: #4a87e2;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;

    .container {
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1140px !important;
      div {
        display: flex;
        align-items: center;
        &:first-child {
          font-size: 14px;
        }
        &:last-child {
          color: black;
          font-size: 12px;
          a {
            text-decoration: none;
            color: black !important;
            cursor: pointer;
            .logo {
              padding: 0 5px;
              svg {
                height: 20px;
              }
            }
            &:hover {
              transform: scale(1.1);
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  footer {
    margin-top: 50px;
    .links-container {
      padding: 0 20px;
      .container {
        width: 100vw;
        max-width: 1024px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        flex-direction: column;
        z-index: 1;
        .info {
          width: 100%;
          padding: 30px 20px;
          .top-pattern {
            display: none;
          }
          .logo {
            img {
              width: 220px;
              height: 50px;
            }
          }
          .header {
            margin-top: 20px;
            margin-bottom: 10px;
            .line {
              width: 40%;
            }
          }
          .items-wrapper {
            display: none;
          }
          .items-wrapper-tablet {
            display: flex !important;
            flex-direction: column;
            gap: 10px;
            .items-wrapper-tablet-top {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              gap: 10px;
            }
            .items-wrapper-tablet-bottom {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              gap: 10px;
            }
          }
          .item {
            font-size: 12px;
            justify-content: flex-start;
            .image {
              svg {
                width: 45px;
                height: 45px;
              }
            }
          }
        }
        .links {
          width: 100%;
          margin-top: 0;
          .line {
            width: 100%;
          }
          .mail {
            .header {
              width: 30%;
            }
            .input {
              height: 38px;
              width: 100%;

              input {
                font-size: 12px;
              }
              button {
                width: 75px;
                font-size: 13px;
              }
            }
          }
          .cattegory {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .radif {
              width: 100%;
              align-items: center;
              .radif-childs {
                justify-content: space-around !important;
                .radif-child {
                  align-items: center;
                }
              }
            }
            .header {
              font-size: 16px;
            }

            .item {
              padding-bottom: 10px;
              cursor: pointer;
              font-size: 11px;
            }
          }
          .socials {
            .header {
              font-size: 16px;
            }
            .svgs {
              .hand {
                svg {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
    .bottom {
      .container {
        width: 100%;

        div {
          &:first-child {
            font-size: 12px;
          }
          &:last-child {
            font-size: 11px;
            a {
              .logo {
                svg {
                  height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  footer {
    margin-top: 30px;
    .links-container {
      width: auto;
      .container {
        padding: 0 10px;
        .info {
          width: 100%;
          box-shadow: none;
          margin-bottom: 10px;
          padding: 15px 10px;
          .social-icons-mobile {
            display: flex !important;
            margin: 0 auto;
            gap: 15px;
            svg {
              width: 20px;
              height: 20px;
              fill: white;
              &:hover {
                fill: #4a87e2;
                transform: scale(0.9);
                transition: 0.3s;
              }
            }
          }
          .header {
            .line {
              width: 30%;
            }
          }
          .item {
            flex-direction: column;
            gap: 10px;
            .text {
              text-align: center;
            }
          }
          .top-pattern {
            display: none;
          }
          .mail-mobile {
            display: block !important;
            .input {
              height: 50px;
              width: 100%;
              background-color: white;
              display: flex;
              padding: 4px;
              justify-content: space-between;
              border-radius: 10px !important;

              border-radius: 100px;
              input {
                height: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                padding-right: 5px;
                width: 90%;
              }
              button {
                width: max-content;
                height: 100%;
                background-color: #2068e1;
                color: white;
                border: none;
                border-radius: 5px;
                padding: 0 10px;
                width: 60%;
                &:hover {
                  background-color: #0f2851;
                  transition: 0.3s;
                }
              }
            }
            p {
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
              padding: 15px;
              border-radius: 5px;
              color: white;
              font-weight: bold;
              z-index: 9999;
            }
            p.success {
              background-color: #4caf50;
            }

            p.error {
              background-color: #f44336;
            }
          }
        }
        .links {
          display: none;
        }
      }
    }
    .bottom {
      padding: 10px;
      .container {
        flex-direction: column;
        gap: 10px;
        div {
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
