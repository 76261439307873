// src/components/apolloClient.js
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core";

export function createApolloClient() {
  return new ApolloClient({
    link: new HttpLink({
      uri: "https://expolymer.ir/blog/graphql", // آدرس GraphQL خود را اینجا وارد کنید
    }),
    cache: new InMemoryCache(),
  });
}
