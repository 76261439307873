<template>
  <div class="polymerModal">
    <div class="body">
      <div>اطلاعات دیتاشیت این ماده به روز نشده</div>
      <a href="https://expolymer.ir">
        <button>تایید</button>
      </a>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.polymerModal {
  width: 100dvw;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10000;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.191);
  direction: rtl;
  .body {
    background-color: white;
    width: 400px;
    height: 200px;
    border-radius: 18px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    div {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      padding-top: 20px;
    }
    button {
      width: 200px;
      background-color: #2068e1;
      color: white;
      border-radius: 8px;
      height: 38px;
      border: none;
    }
  }
}
</style>
