<template>
  <div v-if="isModalOpen" class="share-modal">
    <div class="modal-content">
      <button @click="closeModal" class="close">
        <font-awesome-icon :icon="['fa', 'fa-close']" />
      </button>
      <p>این صفحه را با دوستان خود به اشتراک بگذارید!</p>
      <div class="link-wrapper">
        <button @click="copyLink" class="share-option" id="copyLink">
          <font-awesome-icon :icon="['fa', 'fa-chain']" /> کپی لینک
        </button>
        <button
          @click="shareOnWhatsApp"
          class="share-option"
          id="whatsappShare"
        >
          <font-awesome-icon :icon="['fab', 'fa-whatsapp']" /> واتس‌اپ
        </button>
        <button
          @click="shareOnTelegram"
          class="share-option"
          id="telegramShare"
        >
          <font-awesome-icon :icon="['fab', 'fa-telegram-plane']" /> تلگرام
        </button>
      </div>
    </div>
    <!-- توست -->
    <div :class="['toast', { show: showToast }]">لینک کپی شد!</div>
  </div>
</template>

<script>
export default {
  props: ["currentPageUrl"],
  data() {
    return {
      isModalOpen: true,
      showToast: false, // نمایش توست
    };
  },
  methods: {
    closeModal() {
      this.isModalOpen = false;
      this.$emit("close");
    },
    copyLink() {
      if (!navigator.clipboard) {
        console.error("Clipboard API is not supported!");
        return;
      }
      navigator.clipboard
        .writeText(this.currentPageUrl)
        .then(() => {
          this.showToastMessage();
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
          alert("امکان کپی لینک در این مرورگر وجود ندارد!");
        });
    },
    shareOnWhatsApp() {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
        this.currentPageUrl
      )}`;
      window.open(whatsappUrl, "_blank");
    },
    shareOnTelegram() {
      const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
        this.currentPageUrl
      )}`;
      window.open(telegramUrl, "_blank");
    },
    showToastMessage() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000); // توست بعد از 3 ثانیه مخفی می‌شود
    },
  },
};
</script>

<style scoped>
.share-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #01002880;
  -webkit-backdrop-filter: saturate(180%) blur(5px) !important;
  backdrop-filter: saturate(180%) blur(5px) !important;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 10px;
  max-width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: auto;
  border-radius: 20px;
  margin: 0 auto;
  left: 0;
  top: 0;
}

.modal-content p {
  color: #0f2851;
  font-weight: 700;
  margin-top: 20px;
  box-shadow: #0f285160 0px 15px 15px -16px;
  padding: 20px 0px;
  width: 90%;
}

.link-wrapper {
  text-align: center;
  gap: 10px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

button.share-option {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  min-width: 130px;
  border: none;
  justify-content: center;
  font-size: calc(0.3vw + 12px);
  padding: 10px;
  border-radius: 10px;
  color: white;
}

button.share-option:hover {
  background-color: #2068e1 !important;
}

button#copyLink {
  background-color: #515151;
}

button#whatsappShare {
  background-color: #25d366;
}

button#telegramShare {
  background-color: #24a1de;
}

svg.svg-inline--fa.fa-link,
svg.svg-inline--fa.fa-whatsapp,
svg.svg-inline--fa.fa-telegram {
  font-size: 20px !important;
  width: 30px;
  height: 30px !important;
}

.close {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3334 !important;
  color: white !important;
  border: 2px solid #fff !important;
  border-radius: 50px !important;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 20px;
  transform: translateY(-20px);
}

.close:hover {
  color: black;
}

i.fa-close {
  width: 20px;
  height: 20px;
}

.toast {
  visibility: hidden;
  min-width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.toast.show {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .modal-content {
    max-width: 90% !important;
    width: inherit;
  }
  .link-wrapper {
    flex-direction: column !important;
  }
  svg.svg-inline--fa.fa-link,
  svg.svg-inline--fa.fa-whatsapp,
  svg.svg-inline--fa.fa-telegram {
    font-size: 16px;
    width: 20px;
    height: 20px;
  }
}
</style>
