<template>
  <div id="services">
    <div class="category-container">
      <div class="logo"><ServicesSvg /></div>
      <div class="header">
        <h2>
          <span class="bold py-1">بازار و خدمات </span>
          <span style="color: #0f2851">اِکس پلیمر </span>
        </h2>
        <p class="text">
          برای مشاهده محتویات هر یک از خدمات زیر، آن را انتخاب کنید.
        </p>
      </div>
      <div class="carousel-container">
        <div
          class="arrow-left"
          @click="prevSlide"
          v-show="currentIndex + itemsPerPage < items.length"
        >
          <ArrowRightSvg />
        </div>
        <div class="arrow-right" @click="nextSlide" v-show="currentIndex > 0">
          <ArrowRightSvg />
        </div>
        <div
          class="carousel-container-wrapper"
          :style="{ '--items-per-page': itemsPerPage }"
        >
          <div
            class="carousel"
            :style="{ transform: carouselTransform }"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
          >
            <div
              class="picture"
              v-for="(item, index) in items"
              :key="index"
              @click="goLocation(item.url)"
            >
              <div class="img-container">
                <img :src="item.imgSrc" :alt="item.name" loading="lazy" />
              </div>
              <p class="details">{{ item.text }}</p>
              <div class="text" @click="goLocation(item.url)">
                <span class="svg-con" v-html="item.icon"></span>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="dots-container">
            <span
              v-for="(dot, index) in totalPages"
              :key="index"
              :class="{ active: index === currentPage }"
              @click="goToSlide(index)"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import ServicesSvg from "@/components/svgs/ServicesSvg.vue";
import ArrowRightSvg from "@/components/svgs/ArrowRightSvg.vue";
import items from "./servisesData";

const currentIndex = ref(0);
const windowWidth = ref(window.innerWidth);
const startX = ref(0);
const deltaX = ref(0);

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});

// ✅ تعداد آیتم‌های نمایش داده شده در هر اسلاید
const itemsPerPage = computed(() => {
  if (windowWidth.value >= 1024) return 3; // دسکتاپ ۳ تایی
  if (windowWidth.value >= 768) return 3; // تبلت ۲ تایی
  return 1; // موبایل ۱ تایی
});

// عرض آیتم‌ها و فاصله بین آنها
const itemWidth = computed(() => 100 / itemsPerPage.value); // هر آیتم چند درصد از کل عرض اسلایدر را اشغال کند
const gap = 10; // فاصله بین اسلایدها (بر حسب پیکسل)

// ✅ تعداد صفحات مورد نیاز برای اسلایدر
const totalPages = computed(() => Math.ceil(items.length / itemsPerPage.value));

// ✅ صفحه فعلی که در آن هستیم
const currentPage = computed(() =>
  Math.floor(currentIndex.value / itemsPerPage.value)
);

// ✅ مقدار جابجایی اسلایدر
const carouselTransform = computed(() => {
  return `translateX(${
    currentIndex.value * (itemWidth.value + (gap / windowWidth.value) * 100)
  }%)`;
});

// 🔹 متدهای حرکت بین اسلایدها
const prevSlide = () => {
  if (currentIndex.value + itemsPerPage.value < items.length) {
    currentIndex.value++;
  }
};

const nextSlide = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  }
};

// ✅ کلیک روی نقطه‌های کاروسل
const goToSlide = (index) => {
  currentIndex.value = index * itemsPerPage.value;
};

// ✅ پشتیبانی از لمس در موبایل
const onTouchStart = (event) => {
  startX.value = event.touches[0].clientX;
};

const onTouchMove = (event) => {
  deltaX.value = event.touches[0].clientX - startX.value;
};

const onTouchEnd = () => {
  if (deltaX.value > 50) {
    prevSlide();
  } else if (deltaX.value < -50) {
    nextSlide();
  }
  deltaX.value = 0;
};

// باز کردن لینک
const goLocation = (url) => {
  window.open(url, "_blank");
};
</script>

<style lang="scss" scoped>
.category-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  width: 100vw;
  max-width: 1140px;
  .logo {
    animation: 2s infinite alternate ease-in-out upAndDown;
    padding-bottom: 10px;
    svg {
      width: 65px;
      fill: #1c66e1;
    }
  }

  .header {
    text-align: center;
    .bold {
      color: #1c66e1;
      padding-left: 5px;
    }
    .text {
      font-weight: normal;
      color: #7a7a7a;
      margin-top: 10px;
    }
  }

  .carousel-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 0 -20px;
    max-width: 1140px;
    .arrow-right,
    .arrow-left {
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 3px;
      z-index: 10;
      cursor: pointer;
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .arrow-right {
      order: 1 !important;
    }

    .arrow-left {
      order: 3 !important;
      svg {
        transform: rotate(180deg);
      }
    }

    .carousel-container-wrapper {
      width: 100vw;
      overflow-x: hidden;
      overflow-y: hidden;
      padding: 20px 20px 20px 35px;
      order: 2 !important;
      .carousel {
        display: flex;
        transition: transform 0.5s ease-in-out; /* انیمیشن نرم */
        gap: 10px !important;
      }

      .picture {
        flex: 0 0
          calc(
            (100% - (var(--items-per-page) - 1) * 10px) / var(--items-per-page)
          ); /* عرض هر آیتم با در نظر گرفتن gap */
        transition: transform 0.3s ease-in-out;
        position: relative;
        display: flex;
        flex-direction: column;
        border: #1c66e1 2px solid;
        border-radius: 20px;
        gap: 10px;
        min-height: 100%;
        justify-content: space-between;
        text-decoration: none;
        cursor: pointer;
        margin-left: 5px;
        .img-container {
          border-radius: 18px 18px 0px 0px;
          padding: 2px;
          img {
            border-radius: 18px 18px 0px 0px;
            width: 100%;
          }
        }

        .details {
          position: relative;
          color: black;
          font-size: 15px;
          padding: 0 10px;
          text-align: justify;
          text-decoration: none;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        .text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #1c66e1;
          position: relative;
          bottom: -7px;
          right: -7px;
          padding: 10px 15px;
          gap: 10px;
          width: fit-content;
          border-radius: 16px 0px 16px 0px;
          z-index: 2;
          color: white;

          &:hover {
            cursor: pointer;
            bottom: 0px;
            right: 0px;
            transition: 0.3s;
            background-color: #4a87e2;
          }

          .text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #1c66e1;
            padding: 10px;
            color: #fff;
            border-radius: 0 0 16px 16px;

            .svg-con {
              width: 20px;
              height: 20px;
              fill: #fff;
            }
          }
        }

        &:hover {
          border: #4a87e2 2px solid;
          scale: 0.95;
          .text {
            bottom: 0px;
            right: 0px;
            transition: 0.3s;
            background-color: #4a87e2;
          }
        }
      }
    }
  }
  .dots-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 8px;
    align-items: center;
    span {
      width: 12px;
      height: 12px;
      background: #ddd;
      border-radius: 50%;
      cursor: pointer;
      transition: background 0.3s;

      &.active {
        background: #1c66e1;
        width: 14px;
        height: 14px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .carousel-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 98vw !important;
    margin: 0 -10px;
    .carousel-container-wrapper {
      padding: 20px !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .category-container {
    align-items: center !important;
  }
  .header {
    text-align: center !important;
    font-size: 24px;
  }
  .logo {
    display: none;
  }
  .carousel-container {
    width: 98vw !important;
    margin: 0 -10px;
    .arrow-right,
    .arrow-left {
      cursor: pointer;
      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }
    .carousel-container-wrapper {
      padding: 20px !important;
      .picture {
        margin-left: 0 !important;
      }
    }
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center !important;
    position: relative;
    padding: 7px 10px !important;
    gap: 5px;
    width: 100% !important;
    border-radius: 0px 0px 16px 16px !important;
    z-index: 2;
    right: 0 !important;
    bottom: 0 !important;
  }
}
</style>
