<template>
  <div class="intro-container" id="mainPage">
    <div class="part">
      <h1 class="header">اِکس پلیمر</h1>
      <div class="text">پلیمر سبز، زمین پاک، آینده پایدار</div>

      <a
        data-v-fd8b0366=""
        href="https://expolymer.ir/blog/materials-available/"
        class="red-button"
      >
        مواد موجود برای فروش
      </a>
    </div>
    <div class="part image">
      <img src="/img/homebanner.webp" alt="polymer photo" loading="lazy" />
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.intro-container {
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  padding: 0 20px;
  height: 300px;
  width: 100%;
  .part {
    &:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height: fit-content;
      margin-top: 20px;
      .h1 {
        font-size: 65px !important;
      }
      .red-button {
        border: #0f2851 1px solid;
        display: flex;
        align-items: center;
        background-color: #0f2851;
        cursor: pointer;
        color: white;
        border-radius: 100px;
        padding: 10px 20px;
        font-weight: bold;
        font-size: 13px;
        text-decoration: none;
        &:hover {
          border: #0f2851 1px solid;
          background-color: #ffffff;
          color: #0f2851;
        }
      }
    }
    &:last-child {
      margin-top: 1.6rem;
    }
    &.image {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: auto;
      top: 20px;
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
    /* img {
      height: 440px;
    } */
    .header {
      font-size: 65px;
      font-weight: bold;
      color: #1c66e1;
    }
    .text {
      font-size: 26px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .intro-container {
    width: 100vw;
    height: 270px;

    .part {
      img {
        height: 300px;
      }
      .header {
        font-size: 50px;
      }
      .text {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .intro-container {
    width: 100vw;
    height: 320px;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start !important;
    .part {
      &.image {
        position: absolute;
        left: 0;
        bottom: 15px;
        z-index: -1;
        img {
          width: 100%;
          height: 150px;
        }
      }
      .header {
        font-size: 45px;
      }
      .text {
        font-size: 22px;
      }
    }
  }
}
</style>
