<template>
  <div class="support-container">
    <div class="patterns">
      <img
        src="/img/supportPattern.webp"
        alt="background pattern"
        style="width: 100%"
        loading="lazy"
      />
    </div>
    <div class="section">
      <div class="supoort-wrapper">
        <div class="image">
          <img src="/img/support.webp" alt="support" loading="lazy" />
        </div>
        <div class="paragraph">
          <img src="/img/boxSupport.png" alt="message shape" loading="lazy" />
          <div class="text">
            <h2 class="header">
              <span class="bold">مشاوره با</span>
              اِکس پلیمر
            </h2>
            <p class="details">
              برای مشاوره خرید، بازار و خدمات با کارشناسان اِکس پلیمر با شماره
              ۰۹۱۲۱۰۰۲۱۸۶ تماس بگیرید و یا فرم زیر را پر کنید.
            </p>
            <div class="inputs">
              <div class="input">
                <input
                  class="text"
                  v-model="name"
                  placeholder="نام و نام خانوادگی"
                />
                <input
                  class="number"
                  v-model="mobileNumber"
                  placeholder="تلفن همراه"
                />
              </div>
              <button @click="sendEmail">ثبت</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="patterns">
      <img
        src="/img/supportPattern.webp"
        alt="background pattern"
        style="width: 100%"
        loading="lazy"
      />
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const mobileNumber = ref("");
const name = ref("");
const toast = useToast();
const sendEmail = async () => {
  const config = {
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    service_id: `${process.env.VUE_APP_SERVICE_EMAIL_ID}`,
    template_id: `${process.env.VUE_APP_TEMPLATE_EMAIL_ID}`,
    user_id: `${process.env.VUE_APP_USER_EMAIL_ID}`,
    template_params: {
      message: `شماره تماس : ${mobileNumber.value} نام و نام خانوادگی : ${name.value}`,
    },
  };
  // console.log(process.env);
  try {
    await axios.post(
      `https://api.emailjs.com/api/v1.0/email/send`,
      sendData,
      config
    );
    toast.success("درخواست شما با موفقیت ثبت شد.", {
      position: "top-right",
      timeout: 2000,
    });
  } catch (err) {
    console.log(err);
  }
};
</script>
<style lang="scss" scoped>
.support-container {
  width: 100vw;
  padding-top: 6rem;
  background-color: white;
  z-index: 10;
  .patterns {
    &:first-child {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .section {
    background-color: #edf3fc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .supoort-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      max-width: 1140px;
      padding: 10px;
      gap: 10px;
      margin: 50px 0;
    }
    .image {
      width: 30%;
      img {
        max-width: 300px;
        height: auto;
      }
    }
  }
  .paragraph {
    position: relative;
    z-index: 2;
    width: 66%;
    padding-bottom: 20px;
    img {
      position: absolute;
      width: 100%;
      max-width: 710px;
      top: 30px;
      left: -20px;
      z-index: -1;
      height: 100%;
    }
  }
  .text {
    background-color: #0f2851;
    color: white;
    border-radius: 18px;
    gap: 20px;
    padding: 15px;
    display: grid;

    .details {
      margin-top: -15px;
    }
    .header {
      .bold {
        color: #2068e1;
      }
    }
    .inputs {
      display: flex;
      flex-direction: row; /* به‌صورت افقی */
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      background-color: #2068e1;
      border-radius: 12px;
      padding: 5px 0px 5px 5px;
      width: 100%;
      height: 50px;

      .input {
        display: flex;
        flex-direction: row; /* اینپوت‌ها کنار هم باشند */
        gap: 10px;
        width: 100%;
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        color: white;
        flex: 1;
        height: 100%;
        min-width: 150px;

        &::placeholder {
          color: #b5cef3;
          border-right: 1px solid #0f2851;
          padding-right: 10px;
        }
      }

      button {
        width: auto;
        border: none;
        border-radius: 8px;
        color: white;
        background-color: #0f2851;
        padding: 0 20px;
        height: 100%;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: #2068e1;
          transform: scale(0.9);
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .support-container {
    width: 100%;
    .section {
      .supoort-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        gap: 10px;
        margin: 100px 0;
      }
      .image {
        width: 30%;
        img {
          max-width: 220px;
          height: auto;
        }
      }
    }
    .paragraph {
      position: relative;
      z-index: 2;
      width: 66%;
      padding-bottom: 10px;
      img {
        position: absolute;
        width: 100%;
        max-width: 710px;
        top: 30px;
        left: -20px;
        z-index: -1;
        height: 100%;
      }
    }
    .text {
      .details {
      }
      .header {
      }
      .inputs {
        align-self: flex-end;
        height: 35px;
        padding: 3px;
        button {
          width: 100px;
        }
        input {
          width: 45%;
          font-size: 12px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .support-container {
    width: 100%;
    .section {
      .supoort-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 100px 0;
      }
      .image {
        width: 100%;
        margin: 0 auto;
        display: contents;
        img {
          max-width: 300px;
          height: auto;
        }
      }
      .paragraph {
        position: relative;
        z-index: 2;
        width: 100%;
        margin-top: -120px;
        padding-bottom: 30px;
        margin-right: -20px;
        width: 95%;
        img {
          position: absolute;
          width: 100%;
          max-width: 710px;
          top: 30px;
          left: -20px;
          z-index: -1;
          height: 100%;
        }
        .text {
          height: auto;
          .header {
            text-align: center;
          }
          .details {
            margin: 0;

            text-align: center;
          }
          .inputs {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100% !important;
            button {
              width: 100%;
              font-size: 14px;
              padding: 5px 0;
            }
            .input {
              display: flex;
              padding: 5px;
              flex-direction: column;
              width: 100% !important;
              border-radius: 0 !important;
              padding: 10px;
              &::text {
                width: 100%;
              }
              &::number {
                width: 100%;
              }
              input {
                width: 100% !important;
                font-size: 13px;
                &:last-child {
                  border-bottom: #0f2851 1px solid !important;
                  border-right: none !important;
                }
                &::placeholder {
                  color: #b5cef3;
                  border-right: none !important;
                  padding-right: 0 !important;
                }
                border-bottom: #0f2851 1px solid;
                border-radius: 0 !important;
                padding: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
