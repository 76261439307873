<template>
  <PolymerModal v-if="showModal" />
  <div
    :class="[
      'page-container',
      {
        'modal-open':
          isShareModalOpen || isPriceArchiveOpen || isChartModalOpen,
      },
    ]"
  >
    <div :class="showModal ? 'test' : 'page-container'">
      <NavbarComponent />
      <ContactUsTools />
      <div class="main-container" v-if="rows">
        <!-- استفاده از کامپوننت مودال -->
        <ShareModal
          v-if="isShareModalOpen"
          :currentPageUrl="currentPageUrl"
          @close="closeShareModal"
        />
        <PriceArchive
          v-if="isPriceArchiveOpen"
          :isModalOpen="isPriceArchiveOpen"
          :currentPageUrl="currentPageUrl"
          @close="closePriceArchive"
        />
        <ChartModal
          v-if="isChartModalOpen"
          :isModalOpen="isChartModalOpen"
          :currentPageUrl="currentPageUrl"
          @close="closeChartModal"
        />
        <div class="container">
          <div class="topbanner">
            <div class="back-img">
              <img
                src="../assets/PolymerPage.webp"
                alt="صفحه دیتا شیت"
                style="width: 0; height: 0"
              />
            </div>
            <div class="address">{{ linkGenerator() }}</div>
            <h1 class="title">{{ generalTextReturn("title") }}</h1>
            <div class="buttons">
              <button>دانلود دیتاشیت</button>
              <button @click="openPriceArchive">آرشیو قیمت</button>
            </div>
            <div class="tools">
              <div class="heart">
                <font-awesome-icon :icon="['fas', 'heart']" />
                افزودن به علاقه مندی
              </div>

              <!-- دکمه اشتراک گذاری -->
              <div class="share" @click="openShareModal">
                <font-awesome-icon :icon="['fa', 'share-alt']" />
                اشتراک گذاری
              </div>

              <div class="chart" @click="openChartModal">
                <font-awesome-icon :icon="['fa', 'fa-line-chart']" />
                نمودار قیمت
              </div>
            </div>
            <div class="nav" :style="navStyle" ref="nav">
              <div class="links">
                <div
                  v-for="(section, index) in sections"
                  :key="index"
                  :class="['link', { active: tabActive === index }]"
                  @click="scrollToSection(index)"
                >
                  {{ section.label }}
                </div>
              </div>
            </div>
          </div>

          <div class="part" id="public">
            <h2 :class="tabActive === 0 ? 'header active' : 'header'">
              <span> مشخصات عمومی </span>
            </h2>
            <div class="body" v-if="titleExist('general')">
              <div class="row">
                <div class="title col-2">نام گرید</div>
                <div class="text col">{{ generalTextReturn("title") }}</div>
              </div>
              <div class="row">
                <div class="title col-2">تولید کننده</div>
                <div class="text col">{{ generalTextReturn("builder") }}</div>
              </div>
              <div class="row">
                <div class="title col-2">دسته ها</div>
                <div class="text col">
                  <span
                    class="tag"
                    v-for="item in generalTextReturn('category')"
                    v-bind:key="item"
                    >{{ `#${item}` }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="title col-2">کاربرد ها</div>
                <div class="text col">
                  <span
                    class="tag"
                    v-for="item in generalTextReturn('karbord')"
                    v-bind:key="item"
                    >{{ `#${item}` }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="title col-2">افزوده شده با</div>
                <div class="text col">
                  {{ generalTextReturn("addedwith") }}
                </div>
              </div>
              <div class="row">
                <div class="title col-2">ویژگی ها</div>
                <div class="text col">
                  <span
                    class="tag"
                    v-for="item in generalTextReturn('deatils')"
                    v-bind:key="item"
                  >
                    {{ `#${item}` }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="title col-2">فرایند ها</div>
                <div class="text col">
                  <span
                    class="tag"
                    v-for="item in generalTextReturn('farayand')"
                    v-bind:key="item"
                  >
                    {{ `#${item}` }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="part" id="physical">
            <h2 :class="tabActive === 1 ? 'header active' : 'header'">
              <span> خواص فیزیکی </span>
            </h2>
            <!-- اگر داده‌ای نیست -->
            <div
              v-if="typeof returnTableData('physical') === 'string'"
              style="padding: 10px 0"
            >
              <p style="text-align: justify; color: #202020">
                {{ returnTableData("physical") }}
              </p>
            </div>
            <div class="body" v-if="checkHeader(`physical`)">
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <th>مقدار</th>
                    <th>واحد</th>
                    <th>شرایط آزمون</th>
                    <th>روش آزمون</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in returnTableData('physical')"
                    v-bind:key="item"
                  >
                    <th>{{ item?.name }}</th>
                    <td data-label="مقدار">{{ item?.values[0] }}</td>
                    <td data-label="واحد">{{ item?.values[1] }}</td>
                    <td data-label="شرایط آزمون">{{ item?.values[2] }}</td>
                    <td data-label="روش آزمون">{{ item?.values[3] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="part" id="tempture">
            <h2 :class="tabActive === 2 ? 'header active' : 'header'">
              <span> خواص حرارتی </span>
            </h2>
            <!-- اگر داده‌ای نیست -->
            <div
              v-if="typeof returnTableData('temp') === 'string'"
              style="padding: 10px 0"
            >
              <p style="text-align: justify; color: #202020">
                {{ returnTableData("temp") }}
              </p>
            </div>
            <div class="body" v-if="checkHeader(`temp`)">
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <th>مقدار</th>
                    <th>واحد</th>
                    <th>شرایط آزمون</th>
                    <th>روش آزمون</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in returnTableData('temp')" v-bind:key="item">
                    <th>{{ item?.name }}</th>
                    <td data-label="مقدار">{{ item?.values[0] }}</td>
                    <td data-label="واحد">{{ item?.values[1] }}</td>
                    <td data-label="شرایط آزمون">{{ item?.values[2] }}</td>
                    <td data-label="روش آزمون">{{ item?.values[3] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="part" id="light">
            <h2 :class="tabActive === 3 ? 'header active' : 'header'">
              <span> خواص نوری </span>
            </h2>
            <!-- اگر داده‌ای نیست -->
            <div
              v-if="typeof returnTableData('light') === 'string'"
              style="padding: 10px 0"
            >
              <p style="text-align: justify; color: #202020">
                {{ returnTableData("light") }}
              </p>
            </div>
            <div class="body" v-if="checkHeader(`light`)">
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <th>مقدار</th>
                    <th>واحد</th>
                    <th>شرایط آزمون</th>
                    <th>روش آزمون</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in returnTableData('light')"
                    v-bind:key="item"
                  >
                    <th>{{ item?.name }}</th>
                    <td data-label="مقدار">{{ item?.values[0] }}</td>
                    <td data-label="واحد">{{ item?.values[1] }}</td>
                    <td data-label="شرایط آزمون">{{ item?.values[2] }}</td>
                    <td data-label="روش آزمون">{{ item?.values[3] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="part" id="mechanic">
            <h2 :class="tabActive === 4 ? 'header active' : 'header'">
              <span> خواص مکانیکی </span>
            </h2>
            <!-- اگر داده‌ای نیست -->
            <div
              v-if="typeof returnTableData('mechanic') === 'string'"
              style="padding: 10px 0"
            >
              <p style="text-align: justify; color: #202020">
                {{ returnTableData("mechanic") }}
              </p>
            </div>
            <div class="body" v-if="checkHeader(`mechanic`)">
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <th>مقدار</th>
                    <th>واحد</th>
                    <th>شرایط آزمون</th>
                    <th>روش آزمون</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in returnTableData('mechanic')"
                    v-bind:key="item"
                  >
                    <th>{{ item?.name }}</th>
                    <td data-label="مقدار">{{ item?.values[0] }}</td>
                    <td data-label="واحد">{{ item?.values[1] }}</td>
                    <td data-label="شرایط آزمون">{{ item?.values[2] }}</td>
                    <td data-label="روش آزمون">{{ item?.values[3] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="part" id="process">
            <h2 :class="tabActive === 5 ? 'header active' : 'header'">
              <span> خواص فرایندی </span>
            </h2>
            <!-- اگر داده‌ای نیست -->
            <div
              v-if="typeof returnTableData('process') === 'string'"
              style="padding: 10px 0"
            >
              <p style="text-align: justify; color: #202020">
                {{ returnTableData("process") }}
              </p>
            </div>
            <div class="body" v-if="checkHeader(`process`)">
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <th>مقدار</th>
                    <th>واحد</th>
                    <th>شرایط آزمون</th>
                    <th>روش آزمون</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in returnTableData('process')"
                    v-bind:key="item"
                  >
                    <th>{{ item?.name }}</th>
                    <td data-label="مقدار">{{ item?.values[0] }}</td>
                    <td data-label="واحد">{{ item?.values[1] }}</td>
                    <td data-label="شرایط آزمون">{{ item?.values[2] }}</td>
                    <td data-label="روش آزمون">{{ item?.values[3] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="part others" id="others">
            <h2 :class="tabActive === 6 ? 'header active' : 'header'">
              <span> موارد کاربردی </span>
            </h2>
            <!-- اگر داده‌ای نیست -->
            <div
              v-if="typeof returnTableData('others') === 'string'"
              style="padding: 10px 0"
            >
              <p style="text-align: justify; color: #202020">
                {{ returnTableData("others") }}
              </p>
            </div>
            <div class="text" v-if="checkHeader(`others`)">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
              استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
              ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز
              و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای
              زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و
              متخصصان را می طلبد
            </div>
          </div>
          <div class="part description" id="description">
            <h2 :class="tabActive === 7 ? 'header active' : 'header'">
              <span> توضیحات تکمیلی </span>
            </h2>
            <!-- اگر داده‌ای نیست -->
            <div
              v-if="typeof returnTableData('discription') === 'string'"
              style="padding: 10px 0"
            >
              <p style="text-align: justify; color: #202020">
                {{ returnTableData("discription") }}
              </p>
            </div>
            <p class="text" v-if="checkHeader(`discription`)">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
              استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
              ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز
              و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای
              زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و
              متخصصان را می طلبد
            </p>
          </div>
        </div>
        <div class="disclaimer" ref="related">
          <div class="image">
            <img src="/img/disclaimer.webp" alt="سلب مسئولیت" loading="lazy" />
          </div>
          <div class="disclaimer-text">
            <p>
              مقادیر نمایش داده شده میزان متوسط بوده و نباید به عنوان خواص نهایی
              محصول در نظر گرفته شوند.<br />
              تمامی داده های نشان داده شده از برگه مشخصات فنی محصولات جمع آوری
              گردیده است و تنها برای راهنمایی هستند. <br />
              به دلیل احتمال وجود خطا در داده های وارد شده، هرگونه تصمیم نهایی
              برای کاربری مواد، باید با برگه مشخصات فنی اصلی ماده مطابقت داده
              شود و سایت اکس پلیمر هیچ گونه مسئولیتی در قبال صحت این اطلاعات
              ندارد.
            </p>
          </div>
        </div>

        <div class="related">
          <div class="header-bottom">
            <h2>
              <span style="color: #2068e1">مواد </span>
              <span style="color: #0f2851">مشابه </span>
            </h2>
            <p class="text-bottom">
              برای مشاهده محتویات هر یک از موارد زیر، آن را انتخاب کنید.
            </p>
          </div>
        </div>
      </div>
      <div v-else></div>

      <FooterComponent />
    </div>
  </div>
</template>
<script setup>
import ContactUsTools from "@/components/ContactUsTools.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  onBeforeMount,
  computed,
} from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import PolymerModal from "@/components/PolymerModal.vue";
import ShareModal from "@/components/ShareModal.vue"; // وارد کردن کامپوننت مودال
import PriceArchive from "@/components/PriceArchive.vue";
import ChartModal from "@/components/ChartModal.vue";
// تعریف متغیرها
const rows = ref("");
const showModal = ref(false);
const route = useRoute();
const tabActive = ref(0);
const nav = ref(null);
const isNavFixed = ref(false); // وضعیت فیکس شدن نویگیشن
const related = ref(null);
const isNavVisible = ref(true); // کنترل نمایش نویگیشن
const currentPageUrl = window.location.href;
// ✅ دو متغیر جدا برای هر مودال
const isShareModalOpen = ref(false);
const isPriceArchiveOpen = ref(false);
const isChartModalOpen = ref(false);

const sections = [
  { id: "public", label: "مشخصات عمومی" },
  { id: "physical", label: "خواص فیزیکی" },
  { id: "tempture", label: "خواص حرارتی" },
  { id: "light", label: "خواص نوری" },
  { id: "mechanic", label: "خواص مکانیکی" },
  { id: "process", label: "خواص فرایندی" },
  { id: "others", label: "موارد کاربردی" },
  { id: "description", label: "توضیحات تکمیلی" },
];

// توابع کمکی
const checkHeader = (e) => {
  for (let index = 0; index < rows.value.length; index++) {
    const element = rows.value[index];
    if (element.category === e) {
      return true;
    }
  }
  return false;
};

const linkGenerator = () => {
  let data = rows?.value[0]?.properties;
  if (data) {
    let link = data[0] ? data[0]?.value : null;
    const text = `صفحه اصلی/محصولات/مواد پلیمری/${link}`;
    return text;
  }
};

const titleExist = (searchValue) => {
  let data = rows.value;
  for (const category of data) {
    let exist = category.category.includes(searchValue);
    if (exist) {
      return true;
    }
  }
  return false;
};

const returnTableData = (e) => {
  const d = titleExist(e);
  if (d) {
    const data = rows.value;
    let item = [];
    for (const category of data) {
      if (category.category === e) {
        const properties = category.properties;
        for (let property of properties) {
          property.values = property.value.split(",");
          item.push(property);
        }
      }
    }
    return item.length ? item : "اطلاعاتی برای این محصول یافت نشد.";
  }
  return "اطلاعاتی برای این محصول یافت نشد.";
};

const generalTextReturn = (e) => {
  let data = rows?.value[0]?.properties;
  if (data) {
    if (e === "title") {
      if (data[0]) {
        return data[0].value;
      }
    }
    if (e === "builder") {
      if (data[1]) {
        return data[1].value;
      }
    }
    if (e === "link") {
      if (data[2]) {
        return data[2].value;
      }
    }
    if (e === "category") {
      if (data[3]) {
        const arr = data[3].value
          .split(",")
          .filter((value) => value.trim() !== "");
        return arr;
      }
    }
    if (e === "deatils") {
      if (data[4]) {
        const arr = data[4].value
          .split(",")
          .filter((value) => value.trim() !== "");
        return arr;
      }
    }
    if (e === "farayand") {
      if (data[5]) {
        const arr = data[5].value
          .split(",")
          .filter((value) => value.trim() !== "");
        return arr;
      }
    }
    if (e === "karbord") {
      if (data[6]) {
        const arr = data[6].value
          .split(",")
          .filter((value) => value.trim() !== "");
        return arr;
      }
    }
    if (e === "addedwith") {
      if (data[7]) {
        const arr = data[7].value;
        return arr;
      }
    }
    return false;
  }
};

// ✅ توابع مدیریت مودال‌ها
const openShareModal = () => {
  isShareModalOpen.value = true;
  isPriceArchiveOpen.value = false; // مودال دیگر را ببند
  isChartModalOpen.value = false;
};

const closeShareModal = () => {
  isShareModalOpen.value = false;
};

const openPriceArchive = () => {
  isPriceArchiveOpen.value = true;
  isShareModalOpen.value = false;
  isChartModalOpen.value = false;
};

const closePriceArchive = () => {
  isPriceArchiveOpen.value = false;
};

const openChartModal = () => {
  isChartModalOpen.value = false; // مقدار true را تنظیم کنید
  isPriceArchiveOpen.value = false;
  isShareModalOpen.value = false;
};

const closeChartModal = () => {
  isChartModalOpen.value = false;
};

// دریافت داده‌ها از سرور
const getDataFromServer = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_BASE_API}/api/materialinfo?id=${route.params.id}`
    );
    console.log(data);
    if (data[0]) {
      showModal.value = false; // اگر اطلاعات دریافت شد، مودال بسته می‌شود
    } else {
      showModal.value = true; // اگر داده‌ای نباشد، مودال باز می‌شود
    }
    rows.value = data;
  } catch (err) {
    console.log(err);
    showModal.value = true; // در صورت بروز خطا، مودال نمایش داده می‌شود
  }
};

// تابعی برای اسکرول به بخش
const scrollToSection = (index) => {
  const sectionId = sections[index].id;
  const sectionElement = document.getElementById(sectionId);
  if (sectionElement) {
    const offset = 160; // فاصله از بالای بخش
    const top = sectionElement.offsetTop - offset;
    window.scrollTo({ top, behavior: "smooth" });
    tabActive.value = index; // تغییر وضعیت آیتم نویگیشن
  }
};

// تشخیص بخش فعال هنگام اسکرول
const handleScroll = () => {
  const scrollPosition = window.scrollY;

  // بررسی فیکس شدن نویگیشن
  isNavFixed.value = scrollPosition >= 210;

  /// بررسی موقعیت related برای مخفی کردن nav
  if (related.value && nav.value) {
    const relatedTop = related.value.offsetTop; // موقعیت top عنصر related
    const navHeight = nav.value.offsetHeight; // ارتفاع nav

    // وقتی که اسکرول به 100px قبل از related رسید، nav مخفی می‌شود
    isNavVisible.value = scrollPosition < relatedTop - navHeight - 140;
  }

  // فعال‌سازی لینک‌های نوار ناوبری
  activateNavLink();
};

// تابع برای فعال‌سازی لینک مربوطه در نوار ناوبری
const activateNavLink = () => {
  const scrollPosition = window.scrollY;

  // برای هر بخش، بررسی می‌کنیم که آیا اسکرول در محدوده آن بخش است
  sections.forEach((section, index) => {
    const sectionElement = document.getElementById(section.id);
    if (
      sectionElement.offsetTop <=
        scrollPosition + nav.value.offsetHeight + 160 &&
      sectionElement.offsetTop + sectionElement.offsetHeight > scrollPosition
    ) {
      // اگر بخش فعال است، لینک مربوطه را فعال می‌کنیم
      tabActive.value = index;
    }
  });
};

// هوک‌های چرخه حیات
onBeforeMount(() => {
  getDataFromServer();
});

watch(route, () => {
  document.body.style.overflowY = "auto";
});

onMounted(() => {
  document.body.style.overflowY = "auto";
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

// تابع بررسی برخورد nav با related
const initObserver = () => {
  if (!related.value) return;

  const observer = new IntersectionObserver(
    ([entry]) => {
      isNavVisible.value = !entry.isIntersecting; // به محض برخورد nav با related مخفی می‌شود
    },
    { threshold: 0 } // آستانه 0 برای برخورد سریع‌تر
  );

  observer.observe(related.value);
};

// اصلاح استایل nav برای نمایش/عدم نمایش
const navStyle = computed(() => {
  return {
    position: isNavFixed.value ? "fixed" : "relative",
    marginTop: isNavFixed.value ? "80px" : "0px",
    transform: "translateY(20px)",
    zIndex: 100,
    display: isNavVisible.value ? "flex" : "none",
  };
});

// اجرا هنگام Mounted
onMounted(() => {
  initObserver();
});
</script>

<style lang="scss" scoped>
.test {
  overflow: hidden !important;
  max-height: 100vh;
}

.main-container {
  width: 100vw;
  max-width: 1140px;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1140px;
    .topbanner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 0;
      width: 100vw;
      min-height: 300px;
      padding-top: 80px;
      background: linear-gradient(transparent 5%, #0f2851ce 95%),
        /* لایه نیمه‌شفاف بالا */ url("../assets/PolymerPage.webp"),
        /* تصویر وسط */ transparent; /* رنگ اصلی زیرین */
      background-size: cover;
      background-repeat: no-repeat;
      background-blend-mode: normal; /* ترکیب پس‌زمینه */
      background-position: center;
      .back-img {
        position: absolute;
        z-index: -1;
        top: -50px;
        width: 100%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: auto;
          width: 100%;
        }
      }
      .address {
        color: white;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      .title {
        font-size: 50px;
        color: white;
        margin-bottom: 20px;
        font-weight: bold;
      }
      .buttons {
        display: flex;
        gap: 20px;

        :first-child {
          background-color: #0f2851;
          color: white;
          border: 1px solid white;
          padding: 5px 0;
          border-radius: 10px;
          width: 140px;
          &:hover {
            border: 1px solid #0f2851;
            color: #0f2851;
            background-color: white;
          }
        }
        :last-child {
          background-color: #2068e1;
          border: 1px solid white;
          color: white;
          padding: 5px 0px;
          border-radius: 10px;
          width: 140px;
          &:hover {
            border: 1px solid #0f2851;
            color: #0f2851;
            background-color: white;
          }
        }
      }
      .tools {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        border-radius: 8px;
        padding: 7px;
        color: #0f2851;
        font-size: 12px;
        margin-top: 20px;
        background-color: #ffffff75;
        backdrop-filter: saturate(180%) blur(5px);
        --webkit-backdrop-filter: saturate(180%) blur(5px) !important;
        .chart,
        .heart,
        .share {
          display: flex;
          gap: 5px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          &:hover {
            color: white;
          }
        }
        div {
          border-left: 2px solid white;
          padding: 0 10px;
          &:last-child {
            border: none;
          }
        }
      }
    }
    .nav {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1140px;
      transform: translateY(20px);
      position: relative;
      top: 0;
      z-index: 100;
      margin-top: 0px;

      .links {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        margin: 0px 10px;
        background-color: #f5f5f7;
        padding: 10px 5px;
        gap: 5px;
        .link {
          color: #a5a5a5;
          font-size: 14px;
          cursor: pointer;
          border-bottom: 1px solid #0f285100;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          &:hover {
            color: #0f2851;
            border-bottom: 1px solid #0f2851;
          }
          &.active {
            color: #2068e1;
            font-weight: bold;
            font-size: 16px;
            border-bottom: 1px solid #0f285100 !important;
          }
        }
      }
    }
    .part {
      padding: 0 20px;
      margin-top: 20px;
      width: 100%;
      &:last-child {
        margin-bottom: 12rem;
      }
      &.description {
        font-size: 13px;
        /* font-weight: bold; */

        .text {
          margin-top: 10px;
        }
      }
      .header {
        border-bottom: 4px solid #d7d7d7;
        padding-bottom: 5px;
        color: gray;
        margin-top: 35px;
        position: relative;
        padding-right: 10px;
        span {
          border-bottom: 4px solid #2068e100;
          color: #a5a5a5;
        }
        &.active {
          span {
            border-bottom: 4px solid #2068e1;
            color: gray;
          }
          //color: #2068e1;
          // &::after {
          //   content: "";
          //   position: absolute;
          //   right: 10px;
          //   bottom: -4px; /* Positioning it right below the original border */
          //   width: 120px;
          //   height: 4px;
          //   background-color: #2068e1;
          //   /* transform: translateX(-50%); Center the border */
          // }
        }
      }
      .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #d7d7d7;
        margin-top: 10px;
        .row {
          border-bottom: 1px solid #d7d7d7;
          font-size: 14px;
          display: flex;
          padding: 10px 0;
          .col-2 {
            flex: 0 0 auto;
            width: 20%;
            padding-right: 20px;
          }
          .title {
            font-weight: bold;
          }
          .text {
            line-height: 35px;
            /* white-space: nowrap; */
            padding-right: 20px;
            .tag {
              background-color: #d7d7d7;
              margin-left: 10px;
              padding: 5px;
              border-radius: 6px;
              font-size: 13px;
              word-break: keep-all;
              cursor: pointer;
              &:hover {
                background-color: #2068e1;
                color: white;
              }
            }
          }
        }
        table {
          font-size: 14px !important;
          tr {
            border-bottom: 1px solid #d7d7d7;

            td {
              padding: 8px;
            }
            th {
              padding: 8px;
              width: 10%;
              max-width: 10%;
              background-color: #f0f0f0;
            }
          }
        }
      }
    }
  }
  .disclaimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    padding: 0 40px;
    gap: 20px;
    .image {
      width: 23.33%;

      img {
        max-width: 100%;
        height: auto !important;
      }
    }
    .disclaimer-text {
      width: 73.66%;
      padding: 20px;
      background-color: #2068e1;
      border-radius: 10px;
      box-shadow: 0px 0px 0px 10px rgba(32, 104, 225, 0.6),
        0px 0px 0px 20px rgba(32, 104, 225, 0.4),
        0px 0px 0px 30px rgba(32, 104, 225, 0.2);
      p {
        text-align: justify;
        color: white;
        font-weight: bold;
        line-height: 24px;
      }
    }
  }
  .related {
    display: none;
    padding: 40px 10px 0px 10px;
    margin: 6rem 10px 0px 10px;
    .header-bottom {
      width: 100% !important;
      max-width: 1140px;
      p.text-bottom {
        color: #7a7a7a;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .disclaimer {
    flex-direction: column !important;
    gap: 0px !important;
    justify-content: flex-start !important;

    .image {
      width: 100% !important;
      text-align: center !important;
      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }
    .disclaimer-text {
      width: 90% !important;
      background-color: #1f66e0cf !important;
      backdrop-filter: saturate(180%) blur(5px) !important;
      --webkit-backdrop-filter: saturate(180%) blur(5px) !important;
      transform: translateY(-150%) !important;
      p {
        text-align: center !important;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .main-container {
    padding: 0 0;
    .container {
      .chart,
      .heart,
      .share {
        flex-direction: column !important;
        font-size: 12px !important;
      }
      .topbanner {
        padding-bottom: 20px !important;
      }

      .nav {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .links {
          display: none;
        }
      }

      .row {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .col-2 {
          width: 100% !important;
        }
      }
    }
    thead {
      display: none;
    }

    tbody tr {
      display: flex;
      flex-direction: column-reverse;
      border-bottom: 1px solid #ddd;
      padding: 10px 0;
    }

    tbody tr th {
      order: 1;
      font-weight: bold;
    }

    tbody tr td {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
    }

    tbody tr td::before {
      content: attr(data-label);
      font-weight: bold;
      margin-left: auto; /* قرار دادن در سمت راست */
    }
    th {
      width: 100% !important;
      max-width: 100% !important;
      background-color: transparent !important;
    }
  }
  .disclaimer {
    flex-direction: column !important;
    gap: 0px !important;
    justify-content: flex-start !important;

    .image {
      width: 100% !important;
      text-align: center !important;
      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }
    .disclaimer-text {
      width: 100% !important;
      background-color: #1f66e0cf !important;
      backdrop-filter: saturate(180%) blur(5px) !important;
      --webkit-backdrop-filter: saturate(180%) blur(5px) !important;
      transform: translateY(-50%) !important;
      p {
        text-align: center !important;
      }
    }
  }
}
</style>
