<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="M9.41.72l428.09-.72c35.8,21.54-1.51,59.82-20.36,81.76-40.68,47.35-93.33,93.14-135.44,140.21-29.96,1.17-94.14,10.83-117.62-4.71C151.24,208.75,13.65,62.27,5.86,48.8-.3,38.14-4.78,5.36,9.41.72Z"
    />
    <path
      d="M286.91,245.03v216.95c0,4.68-9.77,8.52-16.75,6.71-23.67-6.16-81.67-53.06-105.76-67.96v-155.69h122.51Z"
    />
  </svg>
</template>
<script setup></script>
