<template>
  <div v-if="isModalOpen" class="price-archive">
    <div class="modal-content">
      <!-- دکمه بستن -->
      <button @click="closeModal" class="close">
        <font-awesome-icon :icon="['fa', 'fa-close']" />
      </button>

      <!-- هدر مودال -->
      <div class="header">
        <font-awesome-icon :icon="['fa', 'fa-archive']" />
        <p>آرشیو قیمت</p>
      </div>

      <p class="details">
        برای دریافت آرشیو قیمت این محصول لطفا با ما تماس بگیرید و یا فرم زیر را
        پر کنید.
      </p>

      <!-- فرم درخواست آرشیو قیمت -->
      <div class="form">
        <div class="inputs">
          <div class="input">
            <input
              class="text"
              v-model="name"
              placeholder="نام و نام خانوادگی"
            />
            <input
              class="number"
              v-model="mobileNumber"
              placeholder="تلفن همراه"
            />
          </div>
          <button @click="sendEmail">ثبت</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export default {
  props: ["isModalOpen"], // دریافت props از کامپوننت والد
  emits: ["close"], // تعریف رویداد برای بستن مودال
  setup(props, { emit }) {
    // متغیرهای ورودی فرم
    const mobileNumber = ref("");
    const name = ref("");

    // اعلان توست
    const toast = useToast();

    // بستن مودال
    const closeModal = () => {
      emit("close");
    };

    // ارسال ایمیل درخواست آرشیو قیمت
    const sendEmail = async () => {
      if (!name.value || !mobileNumber.value) {
        toast.error("لطفا نام و شماره تلفن را وارد کنید.", {
          position: "top-right",
          timeout: 2000,
        });
        return;
      }

      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
      };

      const sendData = {
        service_id: process.env.VUE_APP_SERVICE_EMAIL_ID,
        template_id: process.env.VUE_APP_TEMPLATE_EMAIL_ID,
        user_id: process.env.VUE_APP_USER_EMAIL_ID,
        template_params: {
          message: `درخواست آرشیو قیمت\nنام و نام خانوادگی: ${name.value}\nشماره تماس: ${mobileNumber.value}`,
        },
      };

      try {
        await axios.post(
          "https://api.emailjs.com/api/v1.0/email/send",
          sendData,
          config
        );
        toast.success("درخواست شما با موفقیت ثبت شد.", {
          position: "top-right",
          timeout: 2000,
        });
        // پاک کردن ورودی‌ها پس از ارسال موفق
        name.value = "";
        mobileNumber.value = "";
      } catch (err) {
        console.error("خطا در ارسال درخواست:", err);
        toast.error(
          "مشکلی در ارسال درخواست به وجود آمده است. لطفا دوباره تلاش کنید.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    };

    return {
      mobileNumber,
      name,
      closeModal,
      sendEmail,
    };
  },
};
</script>

<style scoped>
.price-archive {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #01002880;
  -webkit-backdrop-filter: saturate(180%) blur(5px) !important;
  backdrop-filter: saturate(180%) blur(5px) !important;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 10px;
  max-width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: auto;
  border-radius: 20px;
  margin: 0 auto;
  left: 0;
  top: 0;
  gap: 10px;
}

.header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #0f2851;
}

.header p {
  color: #0f2851;
  font-weight: bold;
  font-size: 24px !important;
}

svg.svg-inline--fa.fa-box-archive {
  width: 30px;
  height: 30px;
  color: #0f2851;
}

.details {
  color: #7a7a7a;
  width: 100%;
}

.close {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3334 !important;
  color: white !important;
  border: 2px solid #fff !important;
  border-radius: 50px !important;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 20px;
  transform: translateY(-20px);
}

.close:hover {
  color: black;
}

i.fa-close {
  width: 20px;
  height: 20px;
}

.form {
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: row; /* به‌صورت افقی */
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 12px;
  padding: 5px;
  width: 100%;
  height: 50px;
}
.input {
  display: flex;
  flex-direction: row; /* اینپوت‌ها کنار هم باشند */
  gap: 10px;
  width: 100%;
  height: 100%;
}
.input input {
  background-color: transparent;
  border: 1px solid #0f2851;
  outline: none;
  padding: 0;
  color: gray;
  flex: 1;
  height: 100%;
  min-width: 150px;
  padding-right: 10px;
  border-radius: 10px;
}
.inputs button {
  width: auto;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #0f2851;
  padding: 0 20px;
  height: 100%;
  cursor: pointer;
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .modal-content {
    width: 95% !important;
  }
  .header p {
    font-size: 18px !important;
  }

  svg.svg-inline--fa.fa-box-archive {
    width: 20px !important;
    height: 20px !important;
  }

  .inputs {
    flex-direction: column !important;
    height: 100% !important;
  }
  .input {
    flex-direction: column !important;
  }
  .input input {
    width: 100% !important;
  }
  .inputs button {
    padding: 5px 20px;
    width: 100% !important;
  }
}
</style>
