/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./assets/Style.scss";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { plugin as InputFacade } from "vue-input-facade";
import TrendChart from "vue-trend-chart";
import Vue3PersianDatetimePicker from "vue3-persian-datetime-picker";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueLazyload from "vue-lazyload";
import { faHeart } from "@fortawesome/free-solid-svg-icons"; // اضافه کردن heart
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faLineChart } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"; // اصلاح شده
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons"; // اصلاح شده
import { faChain } from "@fortawesome/free-solid-svg-icons"; // برای کپی لینک
import { faArchive } from "@fortawesome/free-solid-svg-icons";

createApp(App).use(VueLazyload).mount("#app");
// اضافه کردن آیکون‌ها به لایبرری
library.add(
  faCommentAlt,
  faHeart,
  faShareAlt,
  faLineChart,
  faClose,
  faDownload,
  faArchive,
  faWhatsapp, // اضافه کردن آیکون واتس‌اپ
  faTelegramPlane, // اضافه کردن آیکون تلگرام
  faChain // اضافه کردن آیکون کپی لینک
);
const app = createApp(App);

// ثبت کامپوننت‌ها و پکیج‌ها
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(TrendChart);
app.use(InputFacade);
app.component("DatePicker", Vue3PersianDatetimePicker);
app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  rtl: true,
  icon: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
});

// اجرای اپلیکیشن
app.mount("#app");

// تنظیمات ناوبری (Navigation Guards)
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const isAuthenticated = token;
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next("/");
  } else if (to.name === "Login" && isAuthenticated) {
    next("/admin");
  } else {
    next();
  }
});
