<template>
  <div v-if="isModalOpen" class="chart-modal">
    <div class="modal-content">
      <button @click="closeModal" class="close">
        <font-awesome-icon :icon="['fa', 'close']" />
      </button>
      <div class="header">
        <font-awesome-icon :icon="['fa', 'line-chart']" />
        <p>نمودار ماهانه قیمت</p>
      </div>

      <!-- نمایش وضعیت بارگذاری -->
      <div v-if="loading" class="loading">در حال بارگذاری...</div>

      <div
        v-else
        class="price-details"
        v-bind:key="item"
        @click="goPolymer(item)"
      >
        <div class="modal-details-up">
          <div class="modal-details-up-chail">
            <div class="detail-child">
              <span class="label">آخرین تغییرات:</span>
              <div
                class="priceup"
                v-if="compareLastTwoValues(item.prices) && item.prices"
              >
                <span>
                  {{ EnToPrNo(getPercent(item.prices)) }}
                </span>
                <UpSvg />
              </div>
              <div
                class="pricedown"
                v-if="!compareLastTwoValues(item.prices) && item.prices"
              >
                <span>
                  {{ EnToPrNo(getPercent(item.prices)) }}
                </span>
                <DownSvg />
              </div>
            </div>
            <div class="detail-child">
              <span class="label">تاریخ:</span>
              <span> {{ EnToPrNoDate(item.latestCreatedOn) }} </span>
            </div>
          </div>
          <div class="detail-chart">
            <span class="label"></span>
            <!-- نمودار ماهانه-->
            <span
              v-if="
                compareLastTwoValues(item.prices) &&
                item.prices &&
                JSON.parse(item.prices).length > 1
              "
              class="chart-container green"
            >
              <TrendChart
                :datasets="[{ data: JSON.parse(item.prices), fill: true }]"
              />
            </span>
            <span
              v-if="
                !compareLastTwoValues(item.prices) &&
                item.prices &&
                JSON.parse(item.prices)?.length > 1
              "
              class="chart-container"
            >
              <TrendChart
                :datasets="[{ data: JSON.parse(item.prices), fill: true }]"
              />
            </span>
          </div>
        </div>
        <div class="modal-details-down">
          <div class="detail">
            <span class="label">قیمت بازار:</span>
            <span v-if="item.hidePrice === 0">
              <span
                class="priceup"
                v-if="
                  compareLastTwoValues(item.prices) &&
                  item.prices &&
                  item.latestPrice !== 0
                "
              >
                {{ EnToPr(item.latestPrice) }}</span
              >
              <span
                class="pricedown"
                v-if="
                  !compareLastTwoValues(item.prices) &&
                  item.prices &&
                  item.latestPrice !== 0
                "
              >
                {{ EnToPr(item.latestPrice) }}</span
              >
              <DownSvg />
            </span>
            <span
              class="call-button"
              v-if="
                item.hidePrice === 1 ||
                item.latestPrice === 0 ||
                !item.latestPrice
              "
            >
              تماس بگیرید
            </span>
          </div>
          <hr class="vertical" />
          <div class="detail">
            <span class="label">قیمت بورس:</span>
            <span v-if="item.hidePrice === 0">
              {{ EnToPr(item.latestboorsPrice) }}
            </span>
            <span
              class="call-button"
              v-if="
                item.hidePrice === 1 ||
                item.latestboorsPrice === 0 ||
                !item.latestboorsPrice
              "
            >
              تماس بگیرید
            </span>
          </div>
          <hr class="vertical" />
          <div class="detail">
            <span class="label">قیمت تمام شده:</span>
            <span v-if="item.hidePrice === 0">
              {{ EnToPr(item.latestAllPrice) }}
            </span>
            <span
              class="call-button"
              v-if="
                item.hidePrice === 1 ||
                item.latestAllPrice === 0 ||
                !item.latestAllPrice
              "
            >
              تماس بگیرید
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "jalali-moment";
//import UpSvg from "@/components/svgs/UpSvg.vue";
//import DownSvg from "@/components/svgs/DownSvg.vue";
export default {
  props: ["isModalOpen"],
  emits: ["close"],
  data() {
    return {
      item: {},
      loading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    EnToPr(number) {
      if (!number) return "۰";
      return number.toString().replace(/\d/g, (digit) => "۰۱۲۳۴۵۶۷۸۹"[digit]);
    },

    EnToPrNoDate(e) {
      if (!e) return "";
      return moment(e)
        .format("jYYYY/jMM/jDD")
        .replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
    },

    getPercent(e) {
      if (!e) return "۰";
      const arr = JSON.parse(e);
      if (arr.length < 2) return "۰";
      return ((arr[arr.length - 1] / arr[arr.length - 2]) * 100 - 100).toFixed(
        2
      );
    },

    compareLastTwoValues(e) {
      if (!e) return false;
      const arr = JSON.parse(e);
      return arr.length > 1 && arr[arr.length - 1] > arr[arr.length - 2];
    },

    async getMaterial() {
      try {
        this.loading = true;
        const { data } = await axios.get(
          `${process.env.VUE_APP_BASE_API}/api/materialsome`
        );
        this.item = data[0] || {}; // Ensure data is correctly stored in item
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    isModalOpen(newVal) {
      if (newVal) {
        this.getMaterial();
      }
    },
  },
};
</script>

<style scoped>
.chart-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #01002880;
  -webkit-backdrop-filter: saturate(180%) blur(5px) !important;
  backdrop-filter: saturate(180%) blur(5px) !important;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 10px;
  max-width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: auto;
  border-radius: 20px;
  margin: 0 auto;
  gap: 10px;
}

.header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #0f2851;
}

.header p {
  color: #0f2851;
  font-weight: bold;
  font-size: 24px !important;
}

svg.svg-inline--fa.fa-chart-line {
  width: 30px;
  height: 30px;
  color: #0f2851;
}

.close {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3334 !important;
  color: white !important;
  border: 2px solid #fff !important;
  border-radius: 50px !important;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 20px;
  transform: translateY(-20px);
}

.close:hover {
  color: black;
}

.price-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.modal-details-up {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-details-up-chail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 20%;
}

.detail-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  gap: 5px;
}

.detail-chart {
  width: 70%;
  display: flex;
}

.modal-details-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 10px 0px 10px;
  border-top: 1px solid #d7d7d7;
}

.detail {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  gap: 5px;
}

.label {
  font-weight: bold;
  font-size: calc(0.2vw + 10px) !important;
}

span.call-button {
  font-size: calc(0.2vw + 10px) !important;
  color: #7a7a7a;
}

.priceup {
  color: #008001;
}

.priceup svg {
  margin-top: 4px;
  width: 17px;
  height: 17px;
}

.pricedown {
  color: #fe0000;
}

.pricedown svg {
  margin-top: 4px;
  width: 17px;
  height: 17px;
}

hr.vertical {
  border: none;
  width: 2px;
  height: 30px;
  background-color: #7d7d7d;
  transform: rotate(0deg);
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .modal-content {
    width: 95% !important;
  }
  .header p {
    font-size: 18px !important;
  }
  svg.svg-inline--fa.fa-chart-line {
    width: 20px !important;
    height: 20px !important;
  }
  .detail {
    flex-direction: column !important;
    text-align: center;
  }
  .modal-details-up {
    flex-direction: column !important;
  }
  .modal-details-up-chail {
    flex-direction: row !important;
    width: 100% !important;
    justify-content: space-between !important;
  }
  .detail-chart {
    width: 100% !important;
  }
  .detail-child {
    flex-direction: row !important;
  }
}
</style>
