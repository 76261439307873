<template>
  <header :class="['header', { 'sticky--effects': isSticky }]">
    <nav class="navbar">
      <div class="container-menu">
        <div class="leftside">
          <div class="call"><button @click="call">دانلود اپلیکیشن</button></div>
          <div class="icons">
            <div class="menu" @click="setMenu">
              <MenuSvg />
              <div :class="showMenu ? 'popup show' : 'popup'">
                <a class="link" @click="scrollToTop">صفحه اصلی</a>
                <a class="link" href="/services">بازار و خدمات</a>
                <a class="link" href="/#news">اخبار و مقالات</a>
                <a class="link" href="/#aboutus">درباره ما</a>
                <a class="link" href="https://expolymer.ir/blog/contact-us/"
                  >تماس با ما</a
                >
              </div>
            </div>
            <a class="consulting" href="tel:09121002186">
              <!-- <font-awesome-icon :icon="['far', 'comment-alt']" /> -->
              <font-awesome-icon :icon="['fa', 'download']" />
            </a>
            <div class="language">
              <div class="tooltip">انتخاب زبان</div>
              <LanguageSvg />
              <div class="popup">
                <div>
                  <span
                    ><img
                      src="/img/englishflag.webp"
                      alt="English flag"
                      loading="lazy"
                  /></span>
                  <span> English </span>
                </div>
                <hr style="width: 100%; text-align: center; margin: 0px" />
                <div>
                  <span
                    ><img
                      src="/img/turkeyflag.webp"
                      alt="Turkey flag"
                      loading="lazy"
                  /></span>
                  <span> Türkçe </span>
                </div>
                <hr style="width: 100%; text-align: center; margin: 0px" />
                <div>
                  <span
                    ><img
                      src="/img/arabicflag.webp"
                      alt="Saudi Arabia flag"
                      loading="lazy"
                  /></span>
                  <span> العربية </span>
                </div>
              </div>
            </div>
            <!-- <div @click="phone" class="message"><MessageSvg /></div> -->
            <div class="user">
              <AddUserSvg />
              <div class="tooltip">ورود | ثبت‌نام</div>
            </div>
          </div>
        </div>
        <div class="middleside">
          <div class="links">
            <a class="link" @click="scrollToTop">صفحه اصلی</a>
            <a class="link" href="/services">بازار و خدمات</a>
            <a class="link" href="/#news">اخبار و مقالات</a>
            <a class="link" href="/#aboutus">درباره ما</a>
            <a class="link" href="https://expolymer.ir/blog/contact-us/"
              >تماس با ما</a
            >
          </div>
        </div>
        <div class="rightside">
          <span class="logo"
            ><img
              src="/img/ex.webp"
              alt="expolymer logo"
              @click="scrollToTop"
              loading="lazy"
          /></span>
        </div>
      </div>
    </nav>
  </header>
</template>
<script setup>
import LanguageSvg from "./svgs/LanguageSvg.vue";
import AddUserSvg from "./svgs/AddUserSvg.vue";
import MenuSvg from "./svgs/MenuSvg.vue";
import { ref, onMounted, onUnmounted } from "vue";
import router from "@/router";

const showMenu = ref(false);
const isSticky = ref(false); // حالت sticky

const closeMenuOnClickOutside = (event) => {
  if (!event.target.closest(".menu")) {
    showMenu.value = false;
  }
};

const handleScroll = () => {
  if (window.scrollY > 0) {
    isSticky.value = true; // اضافه کردن کلاس هنگام اسکرول
  } else {
    isSticky.value = false; // حذف کلاس وقتی به بالای صفحه برگردید
  }
};

onMounted(() => {
  document.addEventListener("click", closeMenuOnClickOutside);
  window.addEventListener("scroll", handleScroll); // اضافه کردن event listener برای اسکرول
});

onUnmounted(() => {
  document.removeEventListener("click", closeMenuOnClickOutside);
  window.removeEventListener("scroll", handleScroll); // حذف event listener هنگام unmount
});

const setMenu = () => {
  showMenu.value = !showMenu.value;
  document.body.style.overflow = showMenu.value ? "hidden" : "auto";
};

const call = () => {
  window.location.hash = "cantactus";
};

const scrollToTop = () => {
  if (router.currentRoute.value.path !== "/") {
    router.push("/");
  }
  window.scrollTo({
    top: Math.max(0, window.scrollY + 100),
    behavior: "smooth",
  });
};
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0%;
  margin: 0 auto;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent; /* حالت اولیه بدون پس‌زمینه */
  transition: background-color 0.3s, box-shadow 0.3s, padding 0.3s; /* انیمیشن برای تغییرات نرم */
}

.sticky--effects {
  background-color: rgba(15, 40, 81, 1) !important; /* پس‌زمینه هنگام اسکرول */
  box-shadow: 0px 0px 0px 5px rgba(74, 135, 226, 1); /* سایه هنگام اسکرول */
}
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px;
  padding-right: 30px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1140px;
  a {
    text-decoration: none;
  }
  .container-menu {
    background-color: #0f2851;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 20px;
    padding: 10px 0px 10px 10px;
    gap: 10px;
    .middleside {
      .links {
        display: flex;
        align-items: center;
        .link {
          color: white;
          padding: 13px 10px;
          font-size: 15px;
          &:hover {
            color: #1c66e1;
            cursor: pointer;
            text-decoration: solid underline #1c66e1 1px;
            text-underline-offset: 20px;
            transition: 0.2s;
          }
        }
      }
    }
    .rightside {
      position: relative;
      display: flex;
      align-items: center;
      .logo {
        background-color: white;
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 13px -5px rgb(0, 0, 0);
        -moz-box-shadow: 0px 0px 13px -5px rgb(0, 0, 0);
        box-shadow: 0px 0px 13px -5px rgb(0, 0, 0);
        transform: translateX(20px);
        img {
          width: 180px;
          height: auto;
        }
      }
      .links {
        display: flex;
        align-items: center;
        margin-right: 3rem;
        .link {
          position: relative;
          padding: 24px 0;
          font-weight: bold;
          font-size: 15px;
          &:hover {
            color: #3861fb;
            cursor: pointer;
          }
          .hidden-links {
            display: none;
          }
        }
        .polymers {
          &:hover {
            .hidden-links {
              padding: 1rem 0;
              z-index: 10;
              display: flex;
              height: 500px;
              width: 350px;
              top: 60px;
              left: -200px;
              position: absolute;
              background-color: white;
              border-radius: 8px;
              -webkit-box-shadow: 0px 0px 23px -13px rgba(0, 0, 0, 0.64);
              -moz-box-shadow: 0px 0px 23px -13px rgba(0, 0, 0, 0.64);
              box-shadow: 0px 0px 23px -13px rgba(0, 0, 0, 0.64);
              justify-content: space-between;
              .right {
                padding: 0 2rem;
              }
              .left {
                border-right: #eff2f5 1px solid;
                padding: 0 2rem;
              }
              .header {
                color: #a6b0c3;
              }
              .sublinks {
                margin-top: 1rem;
                div {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
    .leftside {
      display: flex;
      direction: ltr;
      align-items: center;
      flex: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      gap: 10px;
      justify-content: center;
      .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .menu {
          display: none;
        }
        .consulting {
          display: none;
        }
        .user {
          position: relative;
          display: none;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            width: 25px;
            height: 25px;
          }
          .tooltip {
            position: absolute;
            bottom: 100%; /* قرار دادن tooltip بالای ایکون */
            left: 50%;
            transform: translate(-50%, -20%);
            background-color: #4a87e2;
            color: white;
            padding: 5px 10px;
            border-radius: 50px;
            font-size: 12px;
            white-space: nowrap;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
          }
          &:hover {
            .tooltip {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        svg {
          fill: white;
          width: 20px;
          height: 20px;
        }
        .message {
          padding-right: 10px;
          transform: rotateY(180deg);
          svg {
            fill: transparent;
            width: 25px;
            height: 25px;
          }
        }
        .language {
          position: relative;
          display: none;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .tooltip {
            position: absolute;
            bottom: 100%; /* قرار دادن tooltip بالای ایکون */
            left: 50%;
            transform: translate(-50%, -20%);
            background-color: #4a87e2;
            color: white;
            padding: 5px 10px;
            border-radius: 50px;
            font-size: 12px;
            white-space: nowrap;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
          }
          .popup {
            display: none;
            position: absolute;
            right: 50%;
            top: 25px;
            font-size: 13px;
            background-color: white;
            padding: 5px;
            border-radius: 20px;
            text-align: center;
            align-items: center;
            -webkit-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
            margin: 0 auto;
            transform: translateX(50%);
            div {
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              gap: 2px;
              margin: 5px;
              justify-content: right;
              width: 100%;
              &:hover {
                color: #3861fb;
                scale: 0.9;
              }
              span {
                &:first-child {
                  padding-left: 7px;
                }
              }
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
          svg {
            width: 23px;
            height: 23px;
          }
          &:hover {
            .tooltip {
              opacity: 1;
              visibility: visible;
            }
            .popup {
              display: flex;
              flex-direction: column;
              div {
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
      .call {
        width: 100%;
        button {
          border: none;
          background-color: #2068e1;
          color: white;
          border-radius: 8px;
          height: 100%;
          padding: 10px 20px;
          width: 100%;
          text-wrap-mode: nowrap;
          &:hover {
            animation: 2s ease 0s infinite beat;
            background-color: #4a87e2;
          }
          @keyframes beat {
            0%,
            50%,
            100% {
              transform: scale(1, 1);
            }
            30%,
            80% {
              transform: scale(0.92, 0.95);
            }
          }
        }
      }
      .menu {
        svg {
          height: 50px;
          width: 40px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  nav {
    .middleside {
      .links {
        display: none !important;
      }
    }
    .rightside {
      .logo {
        right: -45px;
        img {
          width: 150px !important;
          height: auto !important;
        }
      }
    }
    .leftside {
      .icons {
        .menu {
          display: flex !important;
          position: relative;
          .popup {
            display: none;
            &.show {
              display: flex;
              width: 100px;
              flex-direction: column;
              position: absolute;
              background-color: white;
              padding: 5px;
              border-radius: 10px;
              top: 30px;
              left: -10px;
              text-align: center;
              .link {
                margin: 0 9px;
                border-bottom: #0f2851 solid 1px;
                font-size: 13px;
                padding: 5px 0;

                &:last-child {
                  border-bottom: none;
                  padding-bottom: 3px;
                }
              }
              -webkit-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
              box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
            }
          }
          svg {
            width: 25px;
            height: 25px;
          }
        }
        .user {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
        .language {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .consulting {
          color: white; /* رنگ آبی */
          cursor: pointer; /* نشانگر دست برای کلیک‌پذیری */
          transition: color 0.3s ease-in-out;
          display: flex !important;
          position: relative;
        }
      }
      .call {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  nav {
    .container-menu {
      gap: 0 !important;
    }
    .middleside {
      .links {
        display: none !important;
      }
    }
    .rightside {
      .logo {
        right: 0px;
        img {
          width: 130px !important;
          height: auto !important;
        }
      }
    }
    .leftside {
      .icons {
        .menu {
          display: flex !important;
          position: relative;
          .popup {
            display: none;
            &.show {
              display: flex;
              width: 100px;
              flex-direction: column;
              position: absolute;
              background-color: white;
              padding: 5px;
              border-radius: 10px;
              top: 30px;
              left: -10px;
              text-align: center;
              .link {
                margin: 0 9px;
                border-bottom: #0f2851 solid 1px;
                font-size: 13px;
                padding: 5px 0;

                &:last-child {
                  border-bottom: none;
                  padding-bottom: 3px;
                }
              }
              -webkit-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
              box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
            }
          }
          svg {
            width: 25px;
            height: 25px;
          }
        }
        .user {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
        .language {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .consulting {
          color: white; /* رنگ آبی */
          cursor: pointer; /* نشانگر دست برای کلیک‌پذیری */
          transition: color 0.3s ease-in-out;
          display: flex !important;
          position: relative;
        }
      }
      .call {
        display: none;
      }
    }
  }
}
</style>
